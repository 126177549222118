import React, { useEffect, useState } from 'react';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import MediaManager from 'utils/mediaManager';
import {getButtonStyle} from '_base'
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useClassifieds } from 'contexts/classifieds';

const defaultMaskOptions = {
    prefix: '$', 
    suffix: '', 
    includeThousandsSeparator: true, 
    allowDecimal: true, 
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false
};
const currencyMask = createNumberMask(defaultMaskOptions);

function CreateClassified(props) {
    const { member } = useAuth();
    const { dict } = useLang();
    const { saveClassified } = useClassifieds();
    const [label, setLabel] = useState('');
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    
    useEffect(() => {
        if (props.classified) {
            setLabel(props.classified.label);
            setPrice(props.classified.price);
            setDescription(props.classified.description);
            setImages(props.classified.images);
        }
    }, [props.classified])

    const handleCurrencyChange = (event) => {
        let currency = event.target.value;
        let number = Number(currency.replace(/[^0-9.-]+/g,""));
        setPrice(number);
    }
    const uploadMediaHandler = (imageObj) => {
        setImages(images.concat(imageObj));
    }
    const deleteMediaHandler = (imageObj) => {
        let idx = images.findIndex((i) => i.id === imageObj.id);
        console.log('index of image to delete ' + idx);
        let newImgArray = images.splice(idx, 1);
        console.log('newImgArray length ' + newImgArray.length);
        setImages(images);
    }
    /**
     * create the classified ad
     */
    const createClassifedAd = async () => {
        let data = {
            "memberId": member.id, 
            "label": label, 
            "price": price, 
            "description": description,
            "images": images};
        if (props.classified) {
            data["id"] = props.classified.id;
        }
        saveClassified(data, () => props.onDone());
    }
    
    return(
        <div className='create-classified'>
            <div className='back-button-container'>
                <div onClick={props.onDone}><FontAwesomeIcon icon={faAngleLeft}/> {dict.backToClassifieds}</div>
            </div>
            <div className='instructions-area'>
                <div>{dict.classifiedInfo}</div>
            </div>
            <div className='classified-media'>
                <MediaManager 
                    images={images} 
                    onUpload={uploadMediaHandler}
                    onDelete={deleteMediaHandler} />
            </div>
            <div className='classified-details'>
                    <label>{dict.classifiedTitle}</label>
                    <input type='text' onChange={e => setLabel(e.target.value)} value={label} maxLength='64' />
                    <label>{dict.price}</label>
                    <MaskedInput mask={currencyMask} type='text' value={price} onChange={handleCurrencyChange} />
                    <label>{dict.classifiedDescription}</label>
                    <textarea onChange={e => setDescription(e.target.value)} value={description} maxLength='1024' />
            </div>
            <div className='classified-save-button'>
                <button style={getButtonStyle()} onClick={createClassifedAd}>{dict.save}</button>
            </div>
        </div>
    )
}

export default CreateClassified;