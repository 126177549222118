import React, { useEffect, useState } from 'react';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {formatCurrency, getButtonStyle} from '_base';
import ImageViewer from 'utils/imageViewer';
import { useLang } from 'contexts/lang';


function Inventory(props) {
    if (props.selected) {
        return <div className='inventory-item selected'>{props.size + ' ' + props.price}</div>
    } else if (props.quantity > 0) {
        return(
            <div className='inventory-item' onClick={props.onClick}>{props.size + ' ' + props.price}</div>
        );
    } else {
        return <div className='inventory-item disabled'>{props.size + ' ' + props.price}</div>
    }
}
function ProductView(props) {
    const { dict } = useLang();
    const [inventory, setInventory] = useState(null);

    useEffect(() => {
        if (props.product.inventories.length > 0) {
            setInventory(props.product.inventories[0]);
        }
    }, [props.product]);

    const changeInventory = (inventory) => {
        setInventory(inventory);
    }
   
    return (
        <div className='product-view'>
            <div className='product-back-button'>
                <div onClick={props.onBack}><FontAwesomeIcon icon={faAngleLeft}/> {dict.backToStore}</div>
            </div>
            <div className='product-images'>
                <ImageViewer images={props.product.images} />
            </div>
            <div className='product-details'>
                <div className='product-name'>{props.product.name}</div>
                <div className='product-price-and-add-to-cart-button'>
                    <div className='product-price'>{inventory ? formatCurrency(inventory.price) : 'Out of stock'}</div>
                    <div className='add-to-cart-button-holder'>
                        <button style={getButtonStyle()} onClick={() => props.onAddToCart(props.product, inventory)}>{dict.addToCart}</button>
                    </div>
                </div>
                <label>{dict.description}</label>
                <div className='product-description'>{props.product.description}</div>
                <div className='inventory-list'>
                    {
                        props.product.inventories.map((i) => 
                            <Inventory 
                                key={i.id} 
                                size={i.name} 
                                price={formatCurrency(i.price)} 
                                quantity={i.quantity}
                                selected={inventory && inventory.id === i.id}
                                onClick={() => changeInventory(i)} />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductView;