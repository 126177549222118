import React, { useState } from 'react';
import { useCore } from './core';
import { useAuth } from './auth';

const ReactionsContext = React.createContext(null);

function ReactionsProvider({postId, children}) {
    const { runAction } = useCore();
    const { member } = useAuth();
    const [reactions, setReactions] = useState([]);

    const getReactions = async () => {
        const data = {'postId': postId};
        runAction('get_post_reactions', data, (response) => {
            if (response.success) {
                setReactions(response.reactions);
            }
        });
    }

    const createReaction = async (reaction) => {
        const data = {'postId': postId, 'memberId': member.id, 'reaction': reaction};
        runAction('create_post_reaction', data, (response) => {
            if (response.success) {
                getReactions();
            }
        });
    }

    const provider = {
        reactions, 
        createReaction,
    }

    return <ReactionsContext.Provider value={provider}>{children}</ReactionsContext.Provider>
}

function useReactions() {
    const context = React.useContext(ReactionsContext);
    if (!context) {
        throw new Error('useReaction must be used within a ReactionProvider');
    }
    return context;
}

export { ReactionsProvider, useReactions }