import React, { useEffect, useRef, useState } from 'react';
import { useLang } from 'contexts/lang';
import NextButton from 'utils/nextbutton';
import {getButtonStyle} from '_base'
import { useSignin } from 'contexts/signin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/iconButton';

/**
 * password
 */
function Password({onLogin, onForgotPassword}) {
    const { dict } = useLang();
    const { login, loginSuccessful } = useSignin();
    const [error, setError] = useState(null)
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const passwordRef = useRef(null);
    const keepLoggedInRef = useRef(null);

    useEffect(() => {
		passwordRef.current.focus();
		if (localStorage.keepLoggedIn) {
			keepLoggedInRef.current.checked = (localStorage.keepLoggedIn === "true" ? true : false);
		}
	}, []);

    useEffect(() => {
        if (loginSuccessful) {
            onLogin();
        }
    }, [loginSuccessful])
	/**
	 * key up handler
	 */
	const keyUpHandler = (event) => {
		if (event.keyCode && event.keyCode === 13) {
			loginHandler();
		}
	}
	/**
	 * login the user
	 */
	const loginHandler = async() => {
        const ttl = keepLoggedInRef.current.checked ? 24*365 : 24;
        login(password, ttl, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
        });
	}
	const toggleKeepLoggedIn = (event) => {
		localStorage.keepLoggedIn = (keepLoggedInRef.current.checked ? "true" : "false");
	}
	/**
	 * render (lifecycle)
	 */
    return (
        <div>
            <label>{dict.password}</label>
            <div style={{display: 'flex',marginBottom: '30px'}}>
                <div className='password-container'>
                    <input 
                        type={showPassword ? 'text' : 'password'}
                        data-testid='password' 
                        value={password} 
                        onChange={e => setPassword(e.target.value)} 
                        ref={passwordRef} 
                        onKeyUp={keyUpHandler} />
                </div>
                <div className='view-password-toggle'>
                        <IconButton 
                            icon={showPassword ? faEyeSlash : faEye} 
                            onClick={() => setShowPassword(!showPassword)} 
                            label={showPassword ? 'Hide' : 'Show'} />
                </div>
            </div>
            <div className='input-error'>
                {error}
            </div>
            <div className='password-stuff'>
                <div>{dict.keepMeLoggedIn}
                    <input type='checkbox' ref={keepLoggedInRef} onChange={toggleKeepLoggedIn} />
                </div>
            </div>
            <div className='button-container'>
                <button className='secondary' style={getButtonStyle()} onClick={onForgotPassword}>{dict.forgotPassword}</button>
                <NextButton onClick={loginHandler} label={dict.login} />
            </div>
        </div>
    )
}

export default Password;