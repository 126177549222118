import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ClassifiedsContext = React.createContext(null);

function ClassifiedsProvider({children}) {
    const { runAction } = useCore();
    const [classifiedsLoading, setClassifiedsLoading] = useState(true);
    const [classifieds, setClassifieds] = useState([]);

    useEffect(() => {
        getClassifieds();
    }, []);

    const getClassifieds = async () => {
        setClassifiedsLoading(true);
        let data = {'limit': 100, 'offset': 0};
        runAction('get_classifieds', data, (response) => {
            if (response.success) {
                setClassifieds(response.classifieds);
            }
            setClassifiedsLoading(false);
        });
    }

    const saveClassified = async (data, callback) => {
        let action = data.id ? 'update_classified' : 'create_classified';
        runAction(action, data, (response) => {
            if (response.success) {
                getClassifieds();
            }
            callback();
        });
    }

    const deleteClassified = async(classifiedId, callback) => {
        let data = {'id': classifiedId};
        runAction('delete_classified', data, (response) => {
            if (response.success) {
                getClassifieds();
            }
            callback();
        });
    }

    const provider = {
        classifiedsLoading,
        classifieds,
        getClassifieds,
        saveClassified,
        deleteClassified,
    }

    return <ClassifiedsContext.Provider value={provider}>{children}</ClassifiedsContext.Provider>
}

function useClassifieds() {
    const context = React.useContext(ClassifiedsContext);
    if (!context) {
        throw new Error('useClassifieds must be used within a ClassifiedsProvider');
    }
    return context;
}

export { ClassifiedsProvider, useClassifieds }