import React from 'react';
import { Outlet } from 'react-router-dom';
import AppTabs from 'components/appTabs';
import AppOptions from 'components/appOptions';

function Public() {
    return (
        <>
            <div className='body-content'>
                <div className='left-side'>
                    <AppTabs />
                    <AppOptions />
                    <div className='tab-content'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Public;