import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useCore } from './core';
import { useLoaderData, useNavigate } from 'react-router-dom';

const ActivitiesContext = React.createContext(null);

export async function loader({params}) {
    return {start: params.start, end: params.end};
}

function ActivitiesProvider({children}) {
    const { runAction } = useCore();
    const loaderData = useLoaderData();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loadingActivities, setLoadingActivities] = useState(true);
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        if (loaderData) {
            setStartDate(parseInt(loaderData.start));
            setEndDate(parseInt(loaderData.end));
        } else {
            navigate('/portal/member/activities/' + moment().startOf('year').valueOf() + '/' + moment().endOf('year').valueOf());
        }
    }, [loaderData])

    useEffect(() => {
        if (startDate) {
            getActivities();
        }
    }, [startDate, endDate])

    const getActivities = async () => {
        setLoadingActivities(true);
		const data = {'start': startDate, 'end': endDate};
        runAction('get_activities', data, (response) => {
            if (response.success) {
                setActivities(response.activities);
            }
            setLoadingActivities(false);
        });
    }

    const provider = {
        startDate, setStartDate, endDate, setEndDate,
        loadingActivities,
        activities
    }

    return <ActivitiesContext.Provider value={provider}>{children}</ActivitiesContext.Provider>
}

function useActivities() {
    const context = React.useContext(ActivitiesContext);
    if (!context) {
        throw new Error('useActivities must be used within an ActivitiesProvider');
    }
    return context;
}

export { ActivitiesProvider, useActivities }