import React, { useEffect, useState } from 'react';
import { useSession } from 'contexts/session';
import { loadStripe } from '@stripe/stripe-js';
import { usePurchaseOrder } from './purchaseOrder';
import { usePersonalInfo } from './personalInfo';
import { useCore } from 'contexts/core';

const PaymentLoaderContext = React.createContext(null);

function PaymentLoaderProvider({children}) {
    const { tenant, stripe } = useSession();
    const { description, grandTotal } = usePurchaseOrder();
    const { memberId, fname, lname, address1, address2, city, state, zip, country, phone, email } = usePersonalInfo();
    const { runNoAuthAction } = useCore();
    const [paymentLoaderError, setPaymentLoaderError] = useState(null);
    const [promise, setPromise] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const [receiptId, setReceiptId] = useState(null);

    useEffect(() => {
        setPromise(loadStripe(stripe));
    }, []);

    useEffect(() => {
        if (grandTotal) {
            getClientSecret();
        }
    }, [grandTotal]);

    const getClientSecret = async () => {
        let data = {
            'description': description,
            'amount': grandTotal*100,
            'receiptEmail': email,
            'tenantId': tenant.id
        }
        if (memberId) {
            data.memberId = memberId;
        } else {
            data.nonMember = {fname, lname, address1, address2, city, state, zip, country, phone, email};
        }
        runNoAuthAction('create_payment_intent', data, (response) => {
            if (response.success) {
                setClientSecret(response.clientSecret);
                setReceiptId(response.receiptId);
            } else {
                setPaymentLoaderError(response.errorMessage);
            }
        });
    }

    const completePayment = async (status) => {
        if (receiptId) {
            let data = {receiptId, status, 'tenantId': tenant.id}
            runNoAuthAction('stripe_complete_payment', data, (response) => {
                if (!response.success) {
                    setPaymentLoaderError(response.errorMessage);
                    console.error(response.errorMessage);
                }
            });
        }
    }

    const provider = {
        paymentLoaderError,
        promise,
        clientSecret,
        completePayment
    };

    return <PaymentLoaderContext.Provider value={provider}>{children}</PaymentLoaderContext.Provider>
}

function usePaymentLoader() {
    const context = React.useContext(PaymentLoaderContext);
    if (!context) {
        throw new Error('usePaymentLoader must be used within a PaymenLoadertProvider');
    }
    return context;
}

export { PaymentLoaderProvider, usePaymentLoader }