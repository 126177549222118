import React from 'react';
import { useToken } from './token';

const CoreContext = React.createContext(null);
let actionQueue = [];

function CoreProvider({children}) {
    const { token, updateToken } = useToken();
    
    const BASEURL = '/dart/core';

    const runAction = (action, data, handler) => {
        if (token) {
            console.log(action)
            if (actionQueue.length > 0 && actionQueue.find(a => a.action === action) !== undefined) {
                return;
            } else {
                actionQueue.push({action, data});
            }
            // do the fetch
            try {
                fetch(BASEURL, {
                    method: "POST",
                    headers: {'Content-Type':'application/json', 'X-Dart-Token': token},
                    body: JSON.stringify({...data, 'action': action})
                })
                .then((response) => response.json())
                .then((response) => {
                    handler(response);
                })
                .catch((error) => {console.error(error);})
            } catch (error) {
                console.error(error);
            } finally {
                const actionIndex = actionQueue.findIndex(a => a.action == action);
                actionQueue.splice(actionIndex, 1);
            }
        }
    }

    const runNoAuthAction = (action, data, handler) => {
        if (actionQueue.length > 0 && actionQueue.find(a => a.action === action) !== undefined) {
            return;
        } else {
            actionQueue.push({action, data});
        }
        // do the fetch
        fetch(BASEURL, {
                method: "POST",
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({...data, 'action': action})
        }).then((response) => {
            const token = response.headers.get('x-Dart-Token');
            if (token) {
                updateToken(token);
            }
            return response.json()
        }).then(
            (result) => {
                handler(result);
            },
            (error) => {
                console.error(error);
            }
        ).catch((error) => {
            console.error(error);
        }).finally(() => {
            const actionIndex = actionQueue.findIndex(a => a.action == action);
            actionQueue.splice(actionIndex, 1);
        });
    }

    const provider = {
        runAction,
        runNoAuthAction,
    }

    return <CoreContext.Provider value={provider}>{children}</CoreContext.Provider>
}

function useCore() {
    const context = React.useContext(CoreContext);
    if (!context) {
        throw new Error('useCore must be used within a CoreProvider');
    }
    return context;
}

export { CoreProvider, useCore }