import React from 'react';
import { useCore } from './core';

const MemberContext = React.createContext(null);

function MemberProvider({children}) {
    const { runAction } = useCore();
    
    const getMemberInfo = async (memberId, callback) => { 
        const data = {'id': memberId};
        await runAction('get_member', data, (response) => {
            if (response.success) {
                callback(response);
            } else {
                callback(null);
            }
        });
    }

    const getMemberMedia = async (handler) => {
        await runAction('get_member_media', {}, (response) => {
            handler(response);
        });
    }

    const provider = {
        getMemberInfo,
        getMemberMedia
    }

    return <MemberContext.Provider value={provider}>{children}</MemberContext.Provider>
}

function useMember() {
    const context = React.useContext(MemberContext);
    if (!context) {
        throw new Error('useMember must be used within a MemberProvider');
    }
    return context;
}

export { MemberProvider, useMember }