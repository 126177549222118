import React, { useEffect, useState } from 'react';
import { usePurchaseOrder } from './purchaseOrder';

const WorkerInfoContext = React.createContext(null);

const WIKEY = 'workerInfo';

function WorkerInfoProvider({children}) {
    const { completed } = usePurchaseOrder();
    const [isLoading, setIsLoading] = useState(true);
    const [memberId, setMemberId] = useState(null);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [swag, setSwag] = useState('-');
    const [isWiComplete, setIsWiComplete] = useState(false);

    useEffect(() => {
        setIsWiComplete(fname?.length > 0 
            && lname?.length > 0 
            && phone?.length > 0 && email?.length > 0);
    }, [fname, lname, phone, email])

    useEffect(() => {
        if (!isLoading && window.sessionStorage) {
            const piJSONString = JSON.stringify({memberId, fname, lname, phone, email, swag});
            window.sessionStorage.setItem(WIKEY, piJSONString);
        }
    }, [memberId, fname, lname, phone, email, swag])

    useEffect(() => {
        if (window.sessionStorage) {
            const wiJSONString = window.sessionStorage.getItem(WIKEY);
            if (wiJSONString) {
                try {
                    const wiJSON = JSON.parse(wiJSONString);
                    setMemberId(wiJSON.memberId);
                    setFname(wiJSON.fname);
                    setLname(wiJSON.lname);
                    setPhone(wiJSON.phone);
                    setEmail(wiJSON.email);
                    setSwag(wiJSON.swag);
                } catch (err) {
                    console.error(err);
                } finally {
                    window.sessionStorage.removeItem(WIKEY);
                }
            }
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (completed && window.sessionStorage) {
            window.sessionStorage.removeItem(WIKEY);
        }
    }, [completed])

    const resetPi = () => {
        window.sessionStorage.removeItem(WIKEY);
    }

    const provider = {
        memberId, setMemberId,
        fname, setFname,
        lname, setLname,
        phone, setPhone,
        email, setEmail,
        swag, setSwag,
        isWiComplete,
        resetPi
    }

    return <WorkerInfoContext.Provider value={provider}>{children}</WorkerInfoContext.Provider>
}

function useWorkerInfo() {
    const context = React.useContext(WorkerInfoContext);
    if (!context) {
        throw new Error('useWorkerInfo must be used within a WorkerInfoProvider');
    }
    return context;
}

export { WorkerInfoProvider, useWorkerInfo }