import { PersonalInfoProvider } from 'contexts/purchasing/personalInfo';
import { PurchaseOrderProvider } from 'contexts/purchasing/purchaseOrder';
import React from 'react';
import DonationForm from './donationForm';

function Donate() {
    return (
        <div className='events-container'>            
            <div className='events-label'>Make a donation</div>
                <PurchaseOrderProvider type='donationPO'>
                    <PersonalInfoProvider>
                        <DonationForm />
                    </PersonalInfoProvider>
                </PurchaseOrderProvider>
        </div>
    )
}

export default Donate;