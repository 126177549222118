import React from 'react';
import { useCore } from './core';

const MembershipCompletionContext = React.createContext(null);

function MembershipCompletionProvider({children}) {
    const { runNoAuthAction } = useCore();

    const createMembership = async (data, handler) => {
        runNoAuthAction('create_member', data, (response) => {
            handler(response);
        });
    }

    const provider = {
        createMembership,
    }

    return <MembershipCompletionContext.Provider value={provider}>{children}</MembershipCompletionContext.Provider>
}

function useMembershipCompletion() {
    const context = React.useContext(MembershipCompletionContext);
    if (!context) {
        throw new Error('useMembershipCompletion must be used within a MembershipCompletionProvider');
    }
    return context;
}

export { MembershipCompletionProvider, useMembershipCompletion }