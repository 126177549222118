import React, { useRef, useState } from 'react';
import {isSupportedImageType} from 'utils/contentType';
import ImageViewer from './imageViewer';
import ImageCanvas from './imageCanvas';
import { useLang } from 'contexts/lang';

let mediaFileQueue = [];

function MediaManager(props) {
    const { dict } = useLang();
    const [error, setError] = useState(null);
    const [image, setImage] = useState(null);
    const fileInputRef = useRef(null);

    const handleDragOver = (evt) => {
        evt.preventDefault();
		evt.dataTransfer.dropEffect = 'copy';
    }

    const handleMediaDrop = (evt) => {
        let files = null;
        if (evt && evt.dataTransfer) {
            evt.preventDefault();
            files = evt.dataTransfer.files;
        } else {
            files = fileInputRef.current.files;
        }
        if (files) {
            for (let i = 0, f; f = files[i]; i++) {
                let name = escape(f.name);
                console.log("File Info: " 
                        + "Name: " + name + ", " 
                        + "Type: " + (f.type || 'n/a') + ", "
                        + "Size: " + f.size + " bytes, " 
                        + "last modified: " + (f.lastModifiedDate ? f.lastModifiedDate.toLocaleDateString() : "n/a"));
                if (isSupportedImageType(f.type)) {
                    mediaFileQueue.push(f);
                } else {
                    alert(f.name + ' is not a supported image type, please use PNG or JPEG image files.');
                }
            }
            processNextFile();
        }
    }
    const processNextFile = () => {
        if (mediaFileQueue.length > 0) {
            // get the file item
            let file = mediaFileQueue.shift();
            // create the image
            let img = new Image();
            img.onload = () => setImage(img);
            img.src = URL.createObjectURL(file);
        }
    }
    const deleteMediaFile = (imageObj) => {
        console.log("delete media file " + imageObj.id);
        props.onDelete(imageObj);
    }
    const imageSaveHandler = (image) => {
        let imageObj = {id: (Math.floor(Math.random() * -1000)),imageData: image};
        setImage(null);
        props.onUpload(imageObj);
        processNextFile();
    }
    const handleDropZoneClick = () => {
        fileInputRef.current.click();
    }
    
    return (
        <div className='media-manager'>
            <div className='drop-zone' 
                onDragOver={handleDragOver} 
                onDrop={handleMediaDrop} 
                onClick={handleDropZoneClick}>{dict.addPhotos}<br/>{dict.mediaManager1}</div>
            <div>{dict.mediaManager2}</div>
            <div>{error}</div>
            <div>{mediaFileQueue.length > 0 ? dict.processingPhotos.replace('{0}', mediaFileQueue.length) : null}</div>
            <ImageViewer images={props.images} onDelete={deleteMediaFile} />
            {image ? <ImageCanvas image={image} onFinished={imageSaveHandler} /> : null}
            <input type='file' ref={fileInputRef} style={{display:'none'}} onChange={handleMediaDrop} />
        </div>
    )
}

export default MediaManager;