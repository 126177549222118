import React, { useState } from 'react';
import { useCore } from './core';
import { useSession } from './session';

const SigninContext = React.createContext(null);

function SigninProvider({children}) {
    const { runNoAuthAction } = useCore();
    const { tenant } = useSession();
    const [isVerified, setIsVerified] = useState(false);
    const [member, setMember] = useState(null);
    const [hasPassword, setHasPassword] = useState(false);
    const [loginSuccessful, setLoginSuccessful] = useState(false);

    const verifyUsername = async (username, handler) => {
		const data = {
			'tenant': tenant.id,
			'username': username
		};
        setIsVerified(false);
        runNoAuthAction('lookup_member', data, (response) => {
            if (response.success) {
                setIsVerified(true);
                setMember(response.member);
                setHasPassword(response.hasPassword);
            }
            handler(response);
        });
	}

    const setNewPassword = async (password) => {
		const data = {
            'tenant': tenant.id,
            'username': member.email_address,
            'password': password
        };
        runNoAuthAction('member_set_password', data, (response) => {
            if (response.success) {
                setHasPassword(true);
            }
        });
	}

    const login = async (password, ttl, handler) => {
		const data = {
            'tenant': tenant.id,
            'username': member.email_address,
            'password': password,
            ttl
        };
        runNoAuthAction('member_login', data, (response) => {
            if (response.success) {
                setLoginSuccessful(true);
            }
            handler(response);
        });
    }

    const generateTemporaryPassword = async (callback) => {
        let data = {
			'tenant': tenant.id,
			'username': member.email_address
		};
        runNoAuthAction('member_forgot_password', data, (response) => {
            if (response.success) {
                callback();
            }
        });
    }

    const provider = {
        verifyUsername,
        isVerified,
        hasPassword,
        member,
        setNewPassword,
        login, loginSuccessful,
        generateTemporaryPassword
    }

    return <SigninContext.Provider value={provider}>{children}</SigninContext.Provider>
}

function useSignin() {
    const context = React.useContext(SigninContext);
    if (!context) {
        throw new Error('useSignin must be used within a SigninProvider');
    }
    return context;
}

export { SigninProvider, useSignin }