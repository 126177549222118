import React from 'react';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useNavigate } from 'react-router-dom';
import { useWorkerInfo } from 'contexts/purchasing/workerInfo';

function VolunteerSummary() {
    const { description, 
        selectedShifts,
        completed,
        resetPO
    } = usePurchaseOrder();
    const { resetPi } = useWorkerInfo();
    const navigate = useNavigate();

    const cancelHandler = () => {
        resetPO();
        resetPi();
        navigate('/portal/member/events');
    }

    const finishHandler = () => {
        navigate('/portal/member/events');
    }

    return (
        <div className='registration-summary'>
            <div className='registration-summary-title'>Volunteer Summary</div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Selected Shifts</div>
                <div className='item-amount'>{selectedShifts.length}</div>
            </div>
            <hr/>
            
            <div className='registration-summary-buttons'>
                <button className='secondary' onClick={cancelHandler}>Cancel</button>
                <button disabled={!completed} onClick={finishHandler}>Finished</button>
            </div>
        </div>
    )
}

export default VolunteerSummary;