import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {getButtonStyle} from '_base';

/**
 * the next button
 */
function NextButton(props) {
	return ( 
        <>
		    {props.waitMode
			?
                    <button style={getButtonStyle()} data-testid='waitingBtn'><FontAwesomeIcon icon={faSpinner} spin/></button>
			:
				    <button 
                        style={getButtonStyle()} 
                        data-testid='nextBtn' 
                        onClick={() => props.onClick()} 
                        disabled={props.disabled}>{props.label}</button>	
		}
        </>
    )
}

export default NextButton;