import React, { useEffect, useState } from 'react';
import {getButtonStyle} from '_base'

/**
 * an image viewer
 */
function BigImage(props) {
    let imageStyle = null;
    if (props.image) {
        imageStyle = {backgroundImage:'url(' + props.image.imageData + ')',backgrounrRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center'};
    } else {
        // show no images available image
        return(
            <div className='media-noimage'>No Image</div>
        );
    }
    let deleteBtn;
    if (props.onDelete) {
        deleteBtn = <div className='delete-button'><button style={getButtonStyle()} onClick={() => props.onDelete(props.image)}>Delete</button></div>
    }
    return(
        <div className='media-image' style={imageStyle}>
            {deleteBtn}
        </div>
    );
}
/**
 * a thumbnail generator
 */
function Thumbnail(props) {
    let thumbStyle = {backgroundImage:'url(' + props.url + ')'};
    if (props.selected) {
        return(
            <div style={thumbStyle} className='thumbnail-image selected'></div>
        );
    } else {
        return(
            <div style={thumbStyle} className='thumbnail-image' onClick={props.onClick}></div>
        );
    }
}

function ImageViewer(props) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [canDelete, setCanDelete] = useState(props.onDelete ? true : false)
    
    useEffect(() => {
        if (props.images && props.images.length > 0) {
            setSelectedImage(props.images[0]);
        }
    }, [props.images]);

    const showImage = (image) => {
        setSelectedImage(image);
    }

    const deleteImage = (image) => {
        setSelectedImage(null);
        props.onDelete(image);
    }

    return(
        <>
            {(props.images && props.images.length > 0)
                ?
                    <div>
                        {canDelete 
                            ? <BigImage image={selectedImage || props.images[0]} onDelete={deleteImage} /> 
                            : <BigImage image={selectedImage || props.images[0]} />
                        }
                        <div className='thumbnail-images'>
                            {props.images.map((img, i) => 
                                <Thumbnail 
                                    key={img.id ? img.id : i} 
                                    url={img.imageData ? img.imageData : img} 
                                    selected={selectedImage && selectedImage.id === img.id}
                                    onClick={() => showImage(img)} />
                            )}
                        </div>
                    </div>
                : <div><BigImage /></div>
            }
        </>
    )
}

export default ImageViewer;