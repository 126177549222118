import React, { useEffect, useState, useRef } from 'react';
import CreatePassword from './createPassword';
import Password from './password';
import ForgotPassword from './forgotPassword';
import NextButton from 'utils/nextbutton';
import { useLang } from 'contexts/lang';
import { useSignin } from 'contexts/signin';

/**
 * username component
 */
function Username(props) {
    const { dict } = useLang();
    const { verifyUsername, isVerified, hasPassword, member } = useSignin();
    const [error, setError] = useState(null);
    const [username, setUsername] = useState('');

    const [forgotPassword, setForgotPassword] = useState(false);
    const usernameRef = useRef(null);

    useEffect(() => {
		usernameRef.current.focus();
	}, []);
	/**
	 * key up handler
	 */
	const keyUpHandler = (event) => {
		if (event.keyCode && event.keyCode === 13) {
			if (!isVerified) {
				verifyUsernameHandler();
			}
		}
	}
	/**
	 * verify the member username
	 */
	const verifyUsernameHandler = async () => {
            verifyUsername(username, (response) => {
                if (!response.success) {
                    setError(response.errorMessage);
                }
            });
	}
    const forgotPasswordHandler = () => {
        setForgotPassword(true);
    }
	/**
	 * handle the login
	 */
	const loginHandler = () => {
		props.onLogin();
    }

    const goBackToLogin = () => {
        setForgotPassword(false);
    }
	/**
	 * render (lifecycle)
	 */
	return (
        <>
            {isVerified ? (
			    (hasPassword && !forgotPassword)
				?
					<div>
						<div>
							<label>{dict.emailAddress}</label>
							<div>
								<input type='email' name='username' value={username} disabled />
							</div>
						</div>
                        <Password 
                            onLogin={loginHandler} 
                            onForgotPassword={forgotPasswordHandler} />
					</div>
                : (hasPassword && forgotPassword)
                ?
                    <div>
                        <div>
							<label>{dict.emailAddress}</label>
							<div>
								<input type='email' name='username' value={username} disabled />
							</div>
						</div>
                        <ForgotPassword member={member} />
                        <div className='button-container'>
                            <NextButton onClick={goBackToLogin} label={dict.goBackToLogin} />
                        </div>
                    </div>
                :
					<div>
						<div>
							<label>{dict.emailAddress}</label>
							<div>
								<input type='email' name='username' value={username} disabled />
							</div>
						</div>
						<CreatePassword />
					</div>
            ) : (
				<div>
					<label>{dict.emailAddress}</label>
					<div>
						<input type='email' data-testid='username' value={username} onChange={e => setUsername(e.target.value)} ref={usernameRef} onKeyUp={keyUpHandler} />
					</div>
					<div className='input-error'>
						{error}
					</div>
                    <div className='button-container'>
					    <NextButton onClick={verifyUsernameHandler} label={dict.next} disabled={username.length === 0} />
                    </div>
				</div>
			)}
        </>
    )
}

export default Username;