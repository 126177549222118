import React, { useEffect, useState } from 'react';
import Username from './username';
import {setButtonStyle} from '_base';
import { useSession } from 'contexts/session';
import { useNavigate } from 'react-router-dom';
import { useSignin } from 'contexts/signin';

/**
 * signin
 */
function Signin() {
    const navigate = useNavigate();
    const { tenant, portalSettings } = useSession();
	const [style, setStyle] = useState({});

	useEffect(() => {
        if (portalSettings && portalSettings.font && portalSettings.font.length > 0) {
            setStyle({fontFamily: portalSettings.font});
        }

        setButtonStyle(portalSettings);
	}, []);
	
    const loginHandler = () => {
        navigate('/portal/member');
    }

    return (
        <div className='login-container' style={style}>
            <div className='login-center-container'>
                <div className='login-center-pane-outer'>
                    <div className='login-center-pane-inner'>
                        <h1>Log in</h1>
                        <Username tenant={tenant} onLogin={loginHandler} />
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Signin;