import React from 'react';
import ItemTile from 'routes/portal/tile';
import {formatCurrency} from '_base';

function ProductTiles(props) {
    
    const createTile = (product) => {
        let image = null;
        if (product.images.length > 0) {
            image = product.images[0];
        }
        let price = 'Out of stock';
        if (product.inventories && product.inventories.length > 0) {
            //price = product.inventories[0].price;
            let num = product.inventories[0].price;
            price = formatCurrency(num);
        }
        return (
            <ItemTile 
                key={product.id} 
                onClick={() => props.onClick(product)}
                image={image}
                label={product.name} 
                price={price} />
        );
    }

    return (
        <div className='item-tiles'>
            {props.productList.map((p, i) => createTile(p))}
        </div>
    )
}

export default ProductTiles;