import React, { useEffect, useState } from 'react';
import { useSession } from 'contexts/session';
import { useCore } from 'contexts/core';

const ShiftsContext = React.createContext(null);

function ShiftsProvider({eventId, children}) {
    const { tenant } = useSession();
    const { runNoAuthAction } = useCore();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [shifts, setShifts] = useState([]);
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        getAvailableShifts();
    }, [])

    const getAvailableShifts = async () => {
        let data = {tenantId: tenant.id, eventId: eventId, availableOnly: true};
        runNoAuthAction('get_all_shifts', data, (response) => {
            setShifts(response.shifts);
            setSchedules(response.schedules);
            setLoading(false);
        });
    }

    const provider = {
        loading,
        error,
        shifts,
        schedules
    }

    return <ShiftsContext.Provider value={provider}>{children}</ShiftsContext.Provider>
}

function useShifts() {
    const context = React.useContext(ShiftsContext);
    if (!context) {
        throw new Error('useShifts must be used within a ShiftsProvider');
    }
    return context;
}

export { ShiftsProvider, useShifts }