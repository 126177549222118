import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faShoppingCart} from '@fortawesome/pro-regular-svg-icons';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';

function CartIcon(props) {
    const { products } = usePurchaseOrder();

    const getCount = () => {
        return products ? products.length : 0;
    }
       
    return (
        <div className='cart' data-testid='cartBtn' onClick={props.onClick}>
            <FontAwesomeIcon icon={faShoppingCart} />
            <div className='cart-count-bubble'><div className='cart-count'>{getCount()}</div></div>
        </div>
    )
}

export default CartIcon;