import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { getIcon } from 'utils/contentType';
import { useLang } from 'contexts/lang';
import { faFolder, faFolderOpen } from '@fortawesome/pro-regular-svg-icons';
import { useFiles } from 'contexts/files';
/**
 * files
 */
function Files() {
    const { dict } = useLang();
    const { media, folders } = useFiles();
    const [openFolder, setOpenFolder] = useState(null);
    
    const toggleFolderOpen = (folderId) => {
        if (openFolder === folderId) {
            setOpenFolder(null);
        } else {
            setOpenFolder(folderId);
        }
    }

    const mediaList = (folderId) => {
        const filtered = media.filter(m => m.parent == folderId);
        const leftPadding = folderId !== '0' ? '20px' : '0';
        const isShowing = openFolder == folderId || folderId === '0' ? 'flex' : 'none';
        return filtered.map(m =>
            <div className='file-item' key={m.id} style={{paddingLeft: leftPadding, display: isShowing}}>
                <div className='file-icon'><FontAwesomeIcon icon={getIcon(m.contentType)} /></div>
                <div className='file-name'>{m.name}</div>
                <a href={m.url} target='_blank' rel='noopener noreferrer'><FontAwesomeIcon icon={faExternalLink} /></a>
            </div>)
    }

    return (
        <div className='files-container'>
            <div className='files-label'>{dict.files}</div>
            <div className='file-item-list'>
                {folders.map(f =>
                    <div key={f.id}>
                        <div className='file-item' onClick={() => toggleFolderOpen(f.id)}>
                            <div className='file-icon' style={{color:f.color}}><FontAwesomeIcon icon={openFolder === f.id ? faFolderOpen : faFolder}/></div>
                            <div className='file-name'>{f.label}</div>
                        </div>
                        {mediaList(f.id)}
                    </div>
                )}
                {media.length === 0
                    ? <div>{dict.noFiles}</div>
                    : mediaList('0')
                }
            </div>
        </div>
    )
}

export default Files;