import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useSession } from './session';

const EventContext = React.createContext(null);

function EventProvider({eventId, children}) {
    const { tenant } = useSession();
    const { runNoAuthAction } = useCore();
    const [eventLoading, setEventLoading] = useState(true);
    const [event, setEvent] = useState(null);
    const [venue, setVenue] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [reservedSpaces, setReservedSpaces] = useState([]);
    const [eventTickets, setEventTickets] = useState([]);

    useEffect(() => {
        getEvent();
    }, [])

    const getEvent = () => {
        setEventLoading(true);
        runNoAuthAction('get_event', {tenantId: tenant.id, event: eventId}, response => {
            setEvent(response.event);
            setVenue(response.venue);
            setParticipants(response.participants);
            setReservedSpaces(response.reservations);
            setEventTickets(response.eventtickets);
            setEventLoading(false);
        })
    }

    const provider = {
        eventLoading,
        event,
        venue,
        participants,
        reservedSpaces,
        eventTickets,
    }

    return <EventContext.Provider value={provider}>{children}</EventContext.Provider>
}

function useEvent() {
    const context = React.useContext(EventContext);
    if (!context) {
        throw new Error('useEvent must be used within an EventProvider');
    }
    return context;
}

export { EventProvider, useEvent }