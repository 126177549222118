import React from 'react';
import ChildLogins from './childLogins';
import { ChildLoginsProvider } from 'contexts/childLogins';
import { useLang } from 'contexts/lang';

function Children() {
    const { dict } = useLang();
    
    return (
        <div className='settings-view'>
            <h3>{dict.additionalLogins}</h3>
            <div className='setting-item'>
                <ChildLoginsProvider>
                    <ChildLogins />
                </ChildLoginsProvider>
            </div>
        </div>
    )
}

export default Children;