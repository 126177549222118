import React from 'react';

function Avatar({value, width, height, name}) {

    const getLetters = () => {
        let letters = '';
        if (name.trim().length > 0) {
            const parts = name.split(' ');
            if (parts.length > 0) {
                parts.forEach(p => {
                    if (letters.length < 2) {
                        letters += p.trim().length > 0 ? p.trim().charAt(0) : '';
                    }
                })
            }
        }
        return letters;
    }

    return (
        <div className='avatar-container' style={{width:width+'px', height:height+'px'}}>
            {value.length > 0
                ? <canvas width={width} height={height} style={{backgroundImage:`url(${value})`,backgroundSize:'contain'}}></canvas>
                : <div className='letters'>{getLetters()}</div>
            }
        </div>
    )
}

export default Avatar;