import React, { useState } from 'react';
import ChildLogin from './childLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import {getButtonStyle} from '_base';
import { useLang } from 'contexts/lang';
import { useChildLogins } from 'contexts/childLogins';

function ChildLogins() {
    const { dict } = useLang();
    const { childMembers, removeChildMember } = useChildLogins();
    const [error, setError] = useState(null);
    const [addMember, setAddMember] = useState(false);

    const addLoginHandler = () => {
        setAddMember(true);
    }
    const cancelAddMember = () => {
        setAddMember(false);
    }
    const finishedAddMember = () => {
        setAddMember(false);
    }
    const removeChildMemberHandler = async (member) => {
        try {
            await removeChildMember(member.id);
        } catch (e) {
            setError(e.message);
        }
    }
    
    return (    
        <>
            {error && <div>{error}</div>}
            {addMember ?
                <ChildLogin 
                    onCancel={cancelAddMember} 
                    onFinished={finishedAddMember} />
                :
                <div className='child-logins'>
                    <div className='child-login header'>
                        <div>{dict.firstName}</div>
                        <div>{dict.lastName}</div>
                        <div>{dict.email}</div>
                        <div>{dict.actions}</div>
                    </div>
                    {childMembers.length > 0 ?
                        childMembers.map((m) => 
                            <div className='child-login' key={m.id}>
                                <div>{m.fname}</div>
                                <div>{m.lname}</div>
                                <div>{m.email_address}</div>
                                <div><FontAwesomeIcon icon={faTrash} onClick={() => removeChildMemberHandler(m)}/></div>
                            </div>
                        )
                        :
                        <div>{dict.noLogins}</div>
                    }
                    <div className='button-container'>
                        <button style={getButtonStyle()} onClick={addLoginHandler}>{dict.addLogin}</button>
                    </div>
                </div>
            }
        </>
    )
}

export default ChildLogins;