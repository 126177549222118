import React, { useEffect, useState } from 'react';
import { useMember } from 'contexts/member';

function Album() {
    const { getMemberMedia } = useMember();
    const [media, setMedia] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState(null);

    useEffect(() => {
        getMemberMedia((response) => {
            if (response.success) {
                setMedia(response.media);
            }
        });
    }, [])

    return (
        <>
            {media.length > 0 && 
                <div className={'album' + (selectedMedia ? ' full' : '')}>
                    <div className='album-title'>Your Photos</div>
                    <div className='media-viewer'>
                        <div className='media-viewer-header'><button onClick={() => setSelectedMedia(null)}>Close</button></div>
                        {selectedMedia &&
                            <div className='media-viewer-container' style={{backgroundImage: 'url(' + selectedMedia.imageData + ')'}}>

                            </div>
                        }
                    </div>
                    <div className='media-list-container'>
                        <ul>
                            {media.map(m => <li key={m.id} role='button' onClick={() => setSelectedMedia(m)}><Thumbnail url={m.imageData}/></li>)}
                        </ul>
                    </div>
                </div>
            }
        </>
    )
}

/**
 * a thumbnail generator
 */
 function Thumbnail(props) {
    let thumbStyle = {backgroundImage:'url(' + props.url + ')'};
    if (props.selected) {
        return(
            <div style={thumbStyle} className='thumbnail-image selected'></div>
        );
    } else {
        return(
            <div style={thumbStyle} className='thumbnail-image' onClick={props.onClick}></div>
        );
    }
}

export default Album;