import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { parseJwt } from '_base';

const TokenContext = React.createContext(null);

function TokenProvider({children}) {
    const [token, setToken] = useState(null);

    useEffect(() => {
        // get a token if present
		if (sessionStorage.token) {
			let _token = sessionStorage.token;
			if (validateToken(_token)) {
				setToken(_token);
			}
		}
    }, [])

    // validate that a token is valid and not expired
	const validateToken = (tok) => {
		if (tok) {
			let parsedToken = parseJwt(tok);
			if (moment(parsedToken.exp * 1000).isAfter(moment())) {
				return true;
			}
		}
		return false;
	}

    const updateToken = (newToken) => {
        if (validateToken(newToken)) {
            sessionStorage.token = newToken;
            setToken(newToken);
        }
    }

    const clearToken = () => {
        delete (sessionStorage.token);
		setToken(null);
    }

    const provider = {
        token,
        updateToken,
        clearToken,
    }

    return <TokenContext.Provider value={provider}>{children}</TokenContext.Provider>
}

function useToken() {
    const context = React.useContext(TokenContext);
    if (!context) {
        throw new Error('useToken must be used within a TokenProvider');
    }
    return context;
}

export { TokenProvider, useToken }