import React, { useEffect, useState } from 'react';
import PaymentMethod from './paymentMethod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import {getButtonStyle} from '_base'
import { useSettings } from 'contexts/settings';
import Loading from 'components/loading';

function PaymentMethods() {
    const { paymentMethodsLoading, paymentMethods, getPaymentMethods, makePaymentMethodDefault, removePaymentMethod } = useSettings();
    const [error, setError] = useState(null);
    const [createPaymentMethod, setCreatePaymentMethod] = useState(false);
    
    useEffect(() => {
        getPaymentMethodsHandler();
    }, []);

    const getPaymentMethodsHandler = async () => {
        try {
            await getPaymentMethods();
        } catch (e) {
            setError(e.message);
        }
    }

    const addPaymentMethodHandler = () => {
        setCreatePaymentMethod(true);
    }
    const createPaymentMethodHandler = (paymentMethodId) => {
        makeDefaultHandler({id: paymentMethodId});
        setCreatePaymentMethod(false);
        getPaymentMethods();
    }
    const cancelPaymentMethodHandler = () => {
        setCreatePaymentMethod(false);
        getPaymentMethods();
    }
    const makeDefaultHandler = async (pm) => {
        try {
            await makePaymentMethodDefault(pm.id);
        } catch (e) {
            setError(e.message);
        }
    }
    const removePaymentMethodHandler = async (pm) => {
        try {
            await removePaymentMethod(pm.id);
        } catch (e) {
            setError(e.message);
        }
    }
    return(
        <>
            {error && <div>{error}</div>}
            {createPaymentMethod ?
                <div>
                    {console.debug('in paymentMethods')}
                    <PaymentMethod
                        onFinished={createPaymentMethodHandler} 
                        onCancel={cancelPaymentMethodHandler} />
                </div>
            :
                <div className='payment-methods'>
                    <div className='payment-method header'>
                        <div>Type</div>
                        <div>Number</div>
                        <div>Expiration</div>
                        <div>Actions</div>  
                    </div>
                    {paymentMethodsLoading ? <Loading small /> : 
                    paymentMethods.length > 0 ? 
                        paymentMethods.map((pmJson) => {
                            let pm = JSON.parse(pmJson);
                            return (<div className='payment-method' key={pm.id}>
                                <div>{pm.card.brand}</div>
                                <div>{pm.card.last4}</div>
                                <div>{pm.card.exp_month + '/' + pm.card.exp_year}</div>
                                <div><FontAwesomeIcon icon={faTrash} onClick={() => removePaymentMethodHandler(pm)}/></div>
                            </div>)
                        })
                    : 'You have no payment methods setup'}
                    <div className='button-container'>
                        <button style={getButtonStyle()} onClick={addPaymentMethodHandler}>Add Payment Method</button>
                    </div>
                </div>
            }
        </>
    )
}

export default PaymentMethods;