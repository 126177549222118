import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useSettings } from 'contexts/settings';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'contexts/session';

function Membership() {
    const { dict } = useLang();
    const { member } = useAuth();
    const { tenant } = useSession();
    const { settings, saveSettings } = useSettings();
    const [autoRenew, setAutoRenew] = useState(settings.autoRenew);
    const navigate = useNavigate();

    useEffect(() => {
        if (autoRenew !== settings.autoRenew) {
            const settingsData = {...settings, autoRenew};
            saveSettings(settingsData, () => {
                alert('Automatic renewal has been turned ' + (autoRenew ? 'on' : 'off') + '.')
            });
        }
    }, [autoRenew])

    const renewButtonHandler = async () => {
        navigate('/portal/renew');
    }

    return (
        <div className='settings-view'>
            <h3>{dict.membershipRenewal}</h3>
            <div className='setting-item'>
                <div>
                    <label style={{fontWeight:'bold'}}>{dict.membershipExpirationDate} {moment(member.expiration).format('YYYY-MM-DD')}</label>
                    {tenant.settings.maxRenewDays > 0 && 
                        <div style={{fontWeight:'bold'}}>Days until you can renew: {moment(member.expiration).diff(moment(), 'days') - tenant.settings.maxRenewDays}</div>
                    }
                    <div>
                        {(tenant.settings.maxRenewDays === 0 || moment(member?.expiration).diff(moment(), 'days') < tenant.settings.maxRenewDays) && 
                            <div style={{marginTop:'20px'}}>
                                <button onClick={renewButtonHandler}>Renew your membership now</button>
                            </div>
                        }
                    </div>
                    <p>{dict.autoRenewalStatement}</p>
                    <div>{dict.autoRenew} <input type='checkbox' checked={autoRenew} onChange={e => setAutoRenew(e.target.checked)} /></div>
                </div>
            </div>
        </div>
    )
}

export default Membership