import React, { useState } from 'react';
import { useCore } from './core';
import { useSession } from './session';

const RegistrationContext = React.createContext(null);

function RegistrationProvider({children}) {
    const { runNoAuthAction } = useCore();
    const { tenant } = useSession();
    const [foundEntries, setFoundEntries] = useState();

    const findEntries = async (eventId, email) => {
        const data = {tenant: tenant.id, event: eventId, email}
        runNoAuthAction('find_entry', data, (response) => {
            if (!response.success) {
                throw new Error(response.errorMessage);
            } else {
                setFoundEntries(response.entries);
            }
        });
    }

    const createEntry = async (entry) => {
        runNoAuthAction('create_entry', entry, (response) => {
            if (!response.success) {
                throw new Error(response.errorMessage);
            }
        });
    }

    const provider = {
        findEntries,
        foundEntries,
        createEntry,
    }

    return <RegistrationContext.Provider value={provider}>{children}</RegistrationContext.Provider>
}

function useRegistration() {
    const context = React.useContext(RegistrationContext);
    if (!context) {
        throw new Error('useRegistration must be used within a RegistrationProvider');
    }
    return context;
}

export { RegistrationProvider, useRegistration }