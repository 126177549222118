import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const ChildLoginsContext = React.createContext(null);

function ChildLoginsProvider({children}) {
    const { runAction } = useCore();
    const [childMembers, setChildMembers] = useState([]);

    useEffect(() => {
        getChildMembers();
    }, []);

    const getChildMembers = async () => {
        runAction('get_member_children', {}, (response) => {
            if (response.success) {
                setChildMembers(response.members);
            }
        });
    }

    const removeChildMember = async (memberId) => {
        const data = {"action": "delete_member", "id": memberId};
        runAction('delete_member', data, (response) => {
            if (response.success) {
                getChildMembers();
            }
        });
    }

    const createChildMember = async (data, callback) => {
        runAction('create_member', data, (response) => {
            if (response.success) {
                getChildMembers();
                callback();
            } else {
                alert(response.errorMessage);
            }
        });
    }

    const provider = {
        childMembers,
        getChildMembers,
        removeChildMember,
        createChildMember
    }

    return <ChildLoginsContext.Provider value={provider}>{children}</ChildLoginsContext.Provider>
}

function useChildLogins() {
    const context = React.useContext(ChildLoginsContext);
    if (!context) {
        throw new Error('useChildLogins must be used within a ChildLoginsProvider');
    }
    return context;
}

export { ChildLoginsProvider, useChildLogins }