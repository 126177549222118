
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faAngry, faGrin, faHeart, faLaugh, faSadTear, faSurprise, faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';

function Reactions(props) {
    const onReactionHandler = (reaction) => {
        props.onReaction(reaction);
    }
    return (
        <div className='reactions' style={{display:props.showing ? 'flex' : 'none'}}>
            <div className='reaction-buttons'>
            <div className='rbtn' role='button' aria-label='thumbs up' onClick={() => onReactionHandler(0)}><FontAwesomeIcon icon={faThumbsUp} /></div>
            <div className='rbtn' role='button' aria-label='thumbs down' onClick={() => onReactionHandler(1)}><FontAwesomeIcon icon={faThumbsDown} /></div>
            <div className='rbtn' role='button' aria-label='love' onClick={() => onReactionHandler(2)}><FontAwesomeIcon icon={faHeart} /></div>
            <div className='rbtn' role='button' aria-label='care' onClick={() => onReactionHandler(3)}><FontAwesomeIcon icon={faGrin} /></div>
            <div className='rbtn' role='button' aria-label='haha' onClick={() => onReactionHandler(4)}><FontAwesomeIcon icon={faLaugh} /></div>
            <div className='rbtn' role='button' aria-label='wow' onClick={() => onReactionHandler(5)}><FontAwesomeIcon icon={faSurprise} /></div>
            <div className='rbtn' role='button' aria-label='sad' onClick={() => onReactionHandler(6)}><FontAwesomeIcon icon={faSadTear} /></div>
            <div className='rbtn' role='button' aria-label='angry' onClick={() => onReactionHandler(7)}><FontAwesomeIcon icon={faAngry} /></div>
            </div>
        </div>
    );
}

export default Reactions;