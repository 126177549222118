import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { setButtonStyle } from '_base';
import Loading from 'components/loading';
import Header from 'components/header';
import Copyright from 'components/copyright';
import { useSession } from 'contexts/session';
import { AppsProvider } from 'contexts/apps';
import Portal from './portal';
import { useAuth } from 'contexts/auth';
import Public from 'routes/public';

function PortalRoot() {
    const { sessionLoading, portalSettings } = useSession();
    const { member, isExpired } = useAuth();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [style, setStyle] = useState({});
    const [headerStyle, setHeaderStyle] = useState({});

    useEffect(() => {
        console.log('PortalRoot::useEffect - sessionLoading:' + sessionLoading)
        if (!sessionLoading) {
            console.log('PortalRoot::useEffect - pathname:' + pathname)
            if (member) {
                if (isExpired) {
                    navigate('/portal/member/membership');
                } else if (pathname === '/' || pathname === '/portal' || pathname === '/portal/member') {
                    navigate('/portal/member/social')
                }
            } else {
                if (pathname === '/' || pathname === '/portal/member') {
                    navigate('/portal');
                }
            }
        }
    }, [sessionLoading, member])

    useEffect(() => {
        if (portalSettings) { 
            if (portalSettings.font && portalSettings.font.length > 0) {
                setStyle({fontFamily: portalSettings.font});
            }
            let header = {};
            if (portalSettings.headerBackgroundColor && portalSettings.headerBackgroundColor.length > 0) {
                header.backgroundColor = portalSettings.headerBackgroundColor;
            }
            if (portalSettings.headerColor && portalSettings.headerColor.length > 0) {
                header.color = portalSettings.headerColor;
            }
            setHeaderStyle(header);
        }
        setButtonStyle(portalSettings);
    }, [portalSettings]);

    return (
        <div>
            <div className="portal" style={style}>
                <div className="portal-header" style={headerStyle}>
                    {sessionLoading ? <Loading/> :
                        <Header />
                    }
                </div>
                <div className="portal-body">
                    {sessionLoading ? <Loading/> :
                        <AppsProvider>
                            {member ? <Portal /> : <Public />}
                        </AppsProvider>
                    }
                </div>
                <div className='portal-footer'>
                    <Copyright />
                </div>
            </div>
        </div>
    )
}

export default PortalRoot;