import React, { useState } from 'react';
import CreatePost from './socialCreate';
import Album from './album';
import SocialPost from './socialPost';
import {getButtonStyle} from '_base'
import { useLang } from 'contexts/lang';
import { MemberProvider } from 'contexts/member';
import { useSocial } from 'contexts/social';
import Loading from 'components/loading';
import { ReactionsProvider } from 'contexts/reactions';
import { CommentsProvider } from 'contexts/comments';

function Social() {
    const { dict } = useLang();
    const { postsLoading, topics, topicId, setTopicId, posts, getPosts, hasMore, getMore, deletePost } = useSocial();
    const [creating, setCreating] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isComment, setIsComment] = useState(false);
    const [post, setPost] = useState(null);

    const createHandler = () => {
        setCreating(true);
    }
    const newPostCreated = () => {
        setCreating(false);
        setUpdating(false);
        setIsComment(false);
        setPost(null);
        getPosts();
    }
    const editPost = (post) => {
        setUpdating(true);
        setPost(post);
    }
    
    const addComment = (post) => {
        setIsComment(true);
        setPost(post);
    }

    return(
        <>
            {postsLoading
                ? <Loading />
                : (creating || updating || isComment)
                ?   <CreatePost 
                        onDone={newPostCreated} 
                        post={post}
                        isComment={isComment} />
                :
                    <div className='events-container'>
                        <div className='events-label'>
                            <div style={{display:'flex'}}>
                                {dict.posts}
                                <select value={topicId} onChange={evt => setTopicId(evt.target.value)} style={{marginLeft:'5px'}}>
                                    <option value={0}>-All topics-</option>
                                    {topics.map(t => <option value={t.id}>{t.topic}</option>)}
                                </select>
                            </div>
                            <button style={getButtonStyle()} onClick={createHandler}>{dict.createPost}</button>
                        </div>
                        <div className='events-content'>
                            <div className='social-content'>
                            <MemberProvider><Album /></MemberProvider>
                            <div className='post-tiles'>
                                {
                                    posts.length > 0
                                    ? posts.map((p) => 
                                        <ReactionsProvider key={p.post.id} postId={p.post.id}>
                                            <CommentsProvider postId={p.post.id}>
                                                <SocialPost 
                                                    post={p} 
                                                    key={p.post.id} 
                                                    onEdit={editPost} 
                                                    onDelete={() => deletePost(p.post.id)}
                                                    onComment={addComment} />
                                            </CommentsProvider>
                                        </ReactionsProvider>
                                    )
                                    : <div>{dict.postBeTheFirst}</div>
                                }
                            
                                {hasMore ? <div className='get-more-posts' onClick={getMore}><button style={getButtonStyle()}>{dict.get25More}</button></div> : null}
                            </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Social;