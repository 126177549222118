import { formatCurrency, toSpliced } from '_base';
import Loading from 'components/loading';
import { useEvent } from 'contexts/event';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import React from 'react';

function SpacePicker({eventId}) {
    const { eventLoading, venue, reservedSpaces } = useEvent();
    const { spaces, setSpaces } = usePurchaseOrder();
    const venueMap = React.createRef(null);

    const selectElement = (event, element, index) => {
        if (isReserved(element.id) || !element.available) {
            return;
        }
        console.log(element);
        if (isSelected(element.id)) {
            removeSpaceHandler(element.id);
        } else {
            setSpaces([...spaces, {event: eventId, elem: element.id, cost: element.cost, location: (element.row+element.num), notes: element.notes}])
        }
    }
    const removeSpaceHandler = (spaceId) => {
        const spaceIndex = spaces.findIndex(r => r.elem === spaceId);
        if (spaceIndex > -1) {
            let newArray = [...spaces];
            newArray.splice(spaceIndex, 1);
            setSpaces(newArray);
        }
    }
    const isSelected = (spaceId) => {
        return spaces.findIndex(space => space.elem === spaceId) > -1;
    }
    const isReserved = (spaceId) => {
        return reservedSpaces.findIndex(resy => resy.elemId === spaceId) > -1;
    }

    return (
        <div>
            <div className='legend'>
                <div className='key'></div><div className='label'>Available</div>
                <div className='key unavailable'></div><div className='label'>Unavailable</div>
                <div className='key selected'></div><div className='label'>Selected</div>
            </div>
            {eventLoading ? <Loading /> :
                <div className='mapContainer' style={{backgroundImage:`url(${venue.backgroundImage})`,backgroundSize:'contain',
                        backgroundRepeat:'no-repeat'}}>
                    <svg ref={venueMap} version='1.1' viewBox='0,0,1024,768' tabIndex='0'
                        xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                            {venue.elements.map((e, idx) => {
                                let elem = null;
                                switch(e.type) {
                                    case 'box': 
                                        elem = <path id={`p-${idx}`} key={idx} stroke={isSelected(e.id) ? '#F00' : '#000'}
                                            fill={isReserved(e.id) || !e.available ? '#CCC' : (isSelected(e.id) ? '#FAA' : '#FFF')}
                                            d={`M${e.x},${e.y} L${(e.x+e.w)},${e.y} L${(e.x+e.w)},${(e.y+e.h)} L${e.x},${(e.y+e.h)} Z`}
                                            transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null} 
                                            onClick={(evt) => selectElement(evt, e, idx)} />
                                        break;
                                    case 'line':
                                        elem = <path key={idx} stroke='#000' strokeWidth='2'
                                            d={`M${e.x},${e.y} L${e.x+e.w},${e.y}`}
                                            transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null} 
                                            onClick={(evt) => selectElement(evt, e, idx)} />
                                        break;
                                    case 'circle': 
                                        elem = <circle key={idx} stroke='#000' fill={isReserved(e.id) || !e.available ? '#CCC' : '#FFF'}
                                        cx={e.x} cy={e.y} r={e.w} 
                                        onClick={(evt) => selectElement(evt, e, idx)} />
                                        break;
                                    case 'text': 
                                        elem = <text key={idx} fill='#000' x={e.x} y={e.y} fontSize='25' 
                                            transform={!isNaN(e.a) ? `rotate(${e.a},${e.x},${e.y})` : null}
                                            onClick={(evt) => selectElement(evt, e, idx)} >{e.label}</text>
                                        break;
                                    case 'image': 
                                        elem = <image key={idx} xlinkHref={e.href} x={e.x} y={e.y} height={e.h} width={e.w} 
                                        onClick={(evt) => selectElement(evt, e, idx)} />
                                        break;
                                    default: break;
                                }
                                return elem;
                            })}
                            {venue.elements.map((e, idx) => {
                                    let elem = null;
                                    if (e.type === 'box' && e.row.length > 0) {
                                        elem = <text style={{fontSize:'14px'}} dy={14} 
                                                onClick={(evt) => selectElement(evt, e, idx)}>
                                            <textPath xlinkHref={`#p-${idx}`} startOffset='2%' >{`${e.row}${e.num}`}</textPath></text>
                                    }
                                    return elem
                                })}
                    </svg>
                </div>
            }
            <div className='spacesList'>
                <ul>
                {
                    spaces.map(s => 
                        <li>
                            <div>Space {s.location}:</div>
                            <div>{s.notes}</div>
                            <div>{formatCurrency(s.cost)}<button className='linkButton' onClick={() => removeSpaceHandler(s.elem)}>Remove</button></div>
                        </li>)
                }
                </ul>
            </div>
        </div>
    )
}

export default SpacePicker;