import React, { useState } from 'react';
import NextButton from 'utils/nextbutton';
import {getButtonStyle} from '_base'
import {useLang} from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useChildLogins } from 'contexts/childLogins';

function ChildLogin({onFinished}) {
    const { member } = useAuth();
    const { dict } = useLang();
    const { createChildMember } = useChildLogins();
    const [error, setError] = useState(null);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [waitMode, setWaitMode] = useState(false);

    const createMemberHandler = async () => {
        setWaitMode(true);
        const data = {'fname':fname, 'lname':lname, 'email':email, 'parentId': member.id};
        await createChildMember(data, () => {
            onFinished();
            setWaitMode(false);
        });
    }

    return (
        <div className='child-login-info'>
            <p>{dict.provideLoginInfo}</p>
            {error ? <div className='child-login-error'>{error}</div> : null}
            <label>{dict.firstName}</label>
            <input type='text' value={fname} data-testid='fname' onChange={e => setFname(e.target.value)} maxLength='32' />
            <label>{dict.lastName}</label>
            <input type='text' value={lname} data-testid='lname' onChange={e => setLname(e.target.value)} maxLength='32' />
            <label>{dict.emailAddress}</label>
            <input type='text' value={email} data-testid='email' onChange={e => setEmail(e.target.value)} maxLength='64' />
            <div className='button-container'>
            <button style={getButtonStyle()} onClick={onFinished}>{dict.cancel}</button>
            </div>
            <div className='button-container'>
                <NextButton onClick={createMemberHandler} label='Save' waitMode={waitMode} />
            </div>
        </div>
    )
}

export default ChildLogin;