import React, { useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { useLang } from 'contexts/lang';
import { useSession } from 'contexts/session';
import { languageOptions } from 'contexts/languages';
import { faAngleDown, faBars, faGlobe, faSignIn, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { useToken } from 'contexts/token';
import IconButton from './iconButton';
import { useNavigate } from 'react-router-dom';

function Header() {
    const { dict } = useLang();
    const { tenant, portalSettings, isMobile, 
        showingAppMenu, setShowingAppMenu, showingAppOptions, setShowingAppOptions } = useSession();
    const { token } = useToken();
    const navigate = useNavigate();

    const getLogo = () => {
        let logo = null;
        if (portalSettings && portalSettings.logo && portalSettings.logo.length > 0) {
            logo = portalSettings.logo;
            //return <div style={{height:'30px',width:'40px',backgroundImage:`url(${logo})`,backgroundSize:'auto 30px',backgroundRepeat:'no-repeat',marginRight:'5px'}}></div>
        }
        return null;
    }
        
    return(
        <div className='header-content'>
            <div className='content-left'>
                <div className='tenant-icon'>{getLogo()}</div>
                {
                    isMobile && 
                        <div className='mobile-app-menu'>
                            <button className='icon' onClick={() => setShowingAppMenu(!showingAppMenu)}>
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                        </div>
                }
                <div className='tenant-name'>{tenant.name}</div>
            </div>
            <div className='content-right'>
                <LanguageSwitcher />
                <div className='top-bar-divider'></div>
                    {token ?
                    <button className='icon' onClick={() => setShowingAppOptions(!showingAppOptions)}>
                        <FontAwesomeIcon icon={faUserCircle} />
                    </button>
                    : <IconButton icon={faSignIn} label={dict.signIn} onClick={() => navigate('/portal/signin')} />
                    }
            </div>
        </div>
    )
}

function LanguageSwitcher() {
    const { userLanguage, userLanguageChange } = useLang();
    const [showSelect, setShowSelect] = useState(false);

    const handleLangChange = (id) => {
        userLanguageChange(id);
        setShowSelect(false);
    }

    return (
        <div className='language-switcher'>
            <div role='button' onClick={() => setShowSelect(!showSelect)}>
                <FontAwesomeIcon icon={faGlobe} style={{marginRight:'4px'}}/>
                {languageOptions[userLanguage]}
                <FontAwesomeIcon icon={faAngleDown} style={{marginLeft:'4px'}}/>
            </div>
            <div className='lang-select' style={{display: showSelect ? 'block' : 'none'}}>
                <ul>
                    {Object.entries(languageOptions).map(([id, name]) => (
                        <li key={id} onClick={() => handleLangChange(id)}>{name}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}


export default Header;