import React from 'react';
import moment from 'moment';
import { useLang } from 'contexts/lang';
import { useActivities } from 'contexts/activities';
import DatePicker from 'react-datepicker';

function Activities() {
    const { dict } = useLang();
    const { activities, startDate, setStartDate, endDate, setEndDate } = useActivities();

    let monthLabel = '';

    return(
        <div className='events-container'>
            <div className='events-label'>{dict.activities}</div>
            <div className='events-content' style={{border:'none',margin:'10px'}}>
                {startDate && endDate &&
                    <>
                        <label>Start date</label>
                        <DatePicker selected={moment(startDate).toDate()} onChange={d => setStartDate(d.getTime())}/>
                        <label>End date</label>
                        <DatePicker selected={moment(endDate).toDate()} onChange={d => setEndDate(d.getTime())}/>
                    </>
                }
                {
                    activities.length > 0 
                    ? activities.map((e) => {
                        const activityMonthLabel = moment(e.date).format('MMMM YYYY');
                        let showMonthLabel = false;
                        if (monthLabel !== activityMonthLabel) {
                            monthLabel = activityMonthLabel;
                            showMonthLabel = true;
                        }
                        let start = e.start.split(':');
                        let end = e.end.split(':');

                        return (
                        <div key={e.id} className='activity'>
                            {showMonthLabel && <h1>{monthLabel}</h1>}
                            <div className='day'>{moment(e.date).format('dddd Do')}</div>
                            <div className='time'>({moment(e.date).hour(start[0]).minute(start[1]).format('h:mm a')} - 
                            {moment(e.date).hour(end[0]).minute(end[1]).format('h:mm a')})</div>
                            <h3 className='title'>{e.title}</h3>
                            <div className='description'>{e.description}</div>
                        </div>)
                    }) 
                    : <div>{dict.noActivities}</div>
                }
            </div>
        </div>
    )
}

export default Activities;