import React from 'react';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatCurrency, getButtonStyle } from '_base';
import { useLang } from 'contexts/lang';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';

function CartView(props) {
    const { dict } = useLang();
    const { products } = usePurchaseOrder();

    let total = 0;
    let createItem = function(product, key, idx) {
        let imageStyle = null;
        if (product.images.length > 0) {
            imageStyle = {backgroundImage:'url(' + product.images[0].imageData + ')'};
        }
        let price = 'Out of stock';
        if (product.inventory) {
            //price = product.inventories[0].price;
            let num = product.inventory.price;
            total += num;
            price = formatCurrency(num);
        }
        return (
            <div className='cart-item' key={key}>
                <div className='cart-image' style={imageStyle}></div>
                <div className='cart-item-info'>
                    <div>{product.name}</div>
                    <div>{product.description}</div>
                    <div>{dict.quantity}: 1</div>
                    <button style={getButtonStyle()} onClick={() => props.onRemoveFromCart(idx)}>{dict.remove}</button>
                </div>
                <div className='cart-item-price'>{price}</div>
            </div>
        );
    };

    let cartItems = [], key=0;
    if (products && products.length > 0) {
        cartItems = cartItems.concat(products.map((p, idx) => createItem(p, key++, idx)));
    }
    
    if (cartItems.length > 0) {
        return (
            <div className='cart-view'>
                <div className='back-link' onClick={props.onBack}><FontAwesomeIcon icon={faAngleLeft}/> {dict.backToStore}</div>
                {cartItems}
                <div className='cart-total'>{dict.subtotal.replace('{0}', cartItems.length)} {formatCurrency(total)}</div>
                <div className='button-container'>
                    <button style={getButtonStyle()} onClick={props.onPurchaseCart}>{dict.buyNow}</button>
                </div>
            </div>
        );
    } else {
        return(
            <div className='empty-cart-view'>
                <div>{dict.emptyCart}</div>
                <div><button style={getButtonStyle()} onClick={props.onBack}>{dict.goBackToStore}</button></div>
            </div>
        );
    }
}

export default CartView;