import React, { useEffect, useState } from 'react';
import ProductTiles from './productTiles';
import ProductView from './productView';
import CartIcon from './cartIcon';
import CartView from './cartView';
import PurchaseCart from './purchaseCart';
import { useLang } from 'contexts/lang';
import { PersonalInfoProvider } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useSession } from 'contexts/session';
import { useStore } from 'contexts/store';
import { useLoaderData, useNavigate } from 'react-router-dom';

export async function loader({params}) {
    return {itemId: params.itemId};
}

function Store() {
    const { tenant } = useSession();
    const { dict } = useLang();
    const loaderData = useLoaderData();
    const navigate = useNavigate();
    const { setDescription, products, setProducts } = usePurchaseOrder();
    const { productList } = useStore();
    const [product, setProduct] = useState(null);
    const [showingCart, setShowingCart] = useState(false)
    const [purchasingCart, setPurchasingCart] = useState(false);

    useEffect(() => {
        setDescription(tenant.name + ' store')
    }, []);
    
    useEffect(() => {
        if (productList.length > 0 && loaderData) {
            const { itemId } = loaderData;
            if (itemId) {
                const item = productList.find(p => p.id == itemId);
                if (item) {
                    setProduct(item);
                }
            }
        } else if (!loaderData) {
            setProduct(null);
        }
    }, [productList, loaderData])

    const selectProduct = (product) => {
        navigate('/portal/member/store/' + product.id);
    }
    const unselectProduct = () => {
        navigate('/portal/member/store');
    }
    const addToCart = (product, inventory) => {
        let _products = [...products];
        product.inventory = inventory;
        setProducts(_products.concat(product));
        unselectProduct();
    }
    const removeFromCart = (idx) => {
        let _products = [...products];
        _products.splice(idx, 1);
        setProducts(_products);
    }
    const showCart = () => {
        setShowingCart(true);
    }
    const hideCart = () => {
        setShowingCart(false);
    }
    const purchaseCart = () => {
        setShowingCart(false);
        setPurchasingCart(true);
    }
    const cancelPurchase = () => {
        setPurchasingCart(false);
    }
    const completePurchase = () => {
        setPurchasingCart(false)
        setProducts([]);
    }
    
    return(
        <>
            {showingCart ?
                <div>
                    <CartView 
                        onBack={hideCart} 
                        onRemoveFromCart={removeFromCart} 
                        onPurchaseCart={purchaseCart} />
                </div>
            : purchasingCart ?
                <div className='events-container'>
                    <PersonalInfoProvider>
                        <PurchaseCart 
                            onBack={cancelPurchase}
                            onCompletePurchase={completePurchase} />
                    </PersonalInfoProvider>
                </div>
            : (!product) ?
                <div className='events-container'>
                    <div className='events-label'>{dict.clubStore}<CartIcon onClick={showCart}/></div>
                    <div className='events-content'>
                        <ProductTiles productList={productList} onClick={selectProduct} />
                    </div>
                </div>
            : product ?
                <div>
                    <ProductView product={product} onBack={unselectProduct} onAddToCart={addToCart} />
                </div>
            : null
            }
        </>
    )
}

export default Store;
