import React from 'react';
import {useLang} from 'contexts/lang';
import moment from 'moment';
/**
 * the copyright
 */
function Copyright() {
    const { dict } = useLang();
	return (
		<div className='copyright-container'>
			<div>&copy; Copyright {moment().format('YYYY')} Wade Girard</div>
			<div>
				<a href='http://www.middletwin.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>{dict.privacyPolicy}</a> |&nbsp;
				<a href='http://www.middletwin.com/terms-of-use/' target='_blank' rel='noopener noreferrer'>{dict.termsOfUse}</a>
			</div>
			
	</div>);
}

export default Copyright;