import { useSession } from 'contexts/session';
import { useToken } from 'contexts/token';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Logout() {
    const { clearToken } = useToken();
    const { tenant, portalSettings } = useSession();
	const [style, setStyle] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (portalSettings && portalSettings.font && portalSettings.font.length > 0) {
            setStyle({fontFamily: portalSettings.font});
        }

        clearToken();

        const timeoutId = setTimeout(() => {
            navigate('/portal');
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [])

    return (
        <div className='login-container' style={style}>
            <div className='login-center-container'>
                <div className='login-center-pane-outer'>
                    <div className='login-center-pane-inner'>
                        <h1>Log in</h1>
                        <p>Thank you for using the {tenant.name} member portal.
                        You have been loggout out. You can close this browser tab or <Link to='/portal/signin'>login again</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Logout;