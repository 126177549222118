import React, { useState } from 'react';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {formatCurrency, formatPhoneNumber, getButtonStyle} from '_base';
import moment from 'moment';
import ImageViewer from 'utils/imageViewer';
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useClassifieds } from 'contexts/classifieds';
import { useMember } from 'contexts/member';
import { useSettings } from 'contexts/settings';

function ClassifiedView(props) {
    const { member } = useAuth();
    const  { dict } = useLang();
    const { settings } = useSettings();
    const { deleteClassified } = useClassifieds();
    const { getMemberInfo } = useMember();
    const [seller, setSeller] = useState(null);

    const getMemberDetails = async () => {
        if (seller) {
            setSeller(null);
        } else {
            getMemberInfo(props.classified.memberId, (response) => {
                if (response) {
                    setSeller(response);
                }
            });
        }
    }
    const removeListing = async() => {
        deleteClassified(props.classified.id, () => props.onRemove());
    }

    const getAge = () => {
        let daysAgo = moment().diff(moment(props.classified.created), 'days');
        if (daysAgo === 0) {
            daysAgo = 'Listed within the last 24 hours';
        } else {
            daysAgo = 'Listed ' + daysAgo + ' day(s) ago';
        }
        return daysAgo;
    }

    return(
        <div className='product-view'>
            <div className='product-back-button'>
                <div onClick={props.onBack}><FontAwesomeIcon icon={faAngleLeft}/> {dict.backToClassifieds}</div>
            </div>
            <div className='product-images'>
                <ImageViewer images={props.classified.images} />
            </div>
            <div className='product-details'>
                <div className='product-name'>{props.classified.label}</div>
                <div className='product-price-and-add-to-cart-button'>
                    <div className='product-price'>{formatCurrency(props.classified.price)}</div>
                </div>
                <label>{dict.description}</label>
                <div className='product-description'>{props.classified.description}</div>
                <div className='days-ago'>{getAge()}</div>
                {(props.classified.memberId === member.id || settings.moderator) &&
                    
                    <div className='owner-buttons'>
                        <button style={getButtonStyle()} onClick={removeListing}>{dict.remove}</button>
                        <button style={getButtonStyle()} onClick={props.onUpdate}>{dict.update}</button>
                    </div>
                }
                <div className='button-container'>
                <button style={getButtonStyle()} onClick={getMemberDetails}>
                    {seller ? 'Hide' : 'Show'} {dict.contactInfo}</button>
                </div>
                {seller &&
                    <div>
                        <label>{dict.name}</label><div>{seller.fname + ' ' + seller.lname}</div>
                        <label>{dict.email}</label><div>{seller.email_address}</div>
                        <label>{dict.phone}</label><div>
                            {seller.phoneNumbers.length > 0 ? formatPhoneNumber(seller.phoneNumbers[0].longCode) : 'Not Provided'}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ClassifiedView;