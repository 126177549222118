import React from 'react';
import { useCore } from './core';

const ProductsCompletionContext = React.createContext(null);

function ProductsCompletionProvider({children}) {
    const { runAction } = useCore();

    const sellProducts = async (data, handler) => {
        runAction('sell_products', data, (response) => {
            handler(response);
        });
    }

    const provider = {
        sellProducts,
    }

    return <ProductsCompletionContext.Provider value={provider}>{children}</ProductsCompletionContext.Provider>
}

function useProductsCompletion() {
    const context = React.useContext(ProductsCompletionContext);
    if (!context) {
        throw new Error('useProductsCompletion must be used within a ProductsCompletionProvider');
    }
    return context;
}

export { ProductsCompletionProvider, useProductsCompletion }