import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { formatCurrency } from '_base';
import { useSettings } from 'contexts/settings';
import Loading from 'components/loading';

function PaymentHistory() {
    const { paymentHistoryLoading, paymentHistory, getPaymentHistory } = useSettings();

    useEffect(() => {
        getPaymentHistoryHandler();
    }, []);
    
    const getPaymentHistoryHandler = async () => {
        try {
            await getPaymentHistory();
        } catch (e) {
            console.log(e.message);
        }
    }

    return(
        <div className='payment-history'>
            <div className='history-item header'>
                <div>Date &amp; Time</div>
                <div>Amount</div>
                <div>Description</div>
                <div>Status</div>
            </div>
            {paymentHistoryLoading ? <Loading small /> :  
            paymentHistory.length > 0 ? 
                paymentHistory.map((hJson) => {
                    let h = JSON.parse(hJson);
                    return (<div className='history-item' key={h.id}>
                        <div>{moment(h.created * 1000).format('MM/DD/YYYY h:mm:ss a z')}</div>
                        <div>{formatCurrency(h.amount/100)}</div>
                        <div>{h.description}</div>
                        <div>{h.status}</div>
                    </div>)
                })
            : 'You have no recent payment history'}
        </div>
    )
}

export default PaymentHistory;