import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const FilesContext = React.createContext(null);

function FilesProvider({children}) {
    const { runAction } = useCore();
    const [media, setMedia] = useState([]);
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        getMedia();
    }, [])

    const getMedia = async () => {
        let data = {'shared': true};
        runAction('get_media', data, (response) => {
            if (response.success) {
                setMedia(response.media);
                setFolders(response.folders);
            }
        });
    }

    const provider = {
        media,
        folders,
    }

    return <FilesContext.Provider value={provider}>{children}</FilesContext.Provider>
}

function useFiles() {
    const context = React.useContext(FilesContext);
    if (!context) {
        throw new Error('useFiles must be used within a FilesProvider');
    }
    return context;
}

export { FilesProvider, useFiles }