import { useApps } from 'contexts/apps';
import { useSession } from 'contexts/session';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AppTabs() {
    const { portalSettings, showingAppMenu, setShowingAppMenu } = useSession();
    const { appList, isValidRoute } = useApps();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [tabStyle, setTabStyle] = useState({});

    useEffect(() => {
        // set the tab style
        let style = {};
        if (portalSettings.headerBackgroundColor && portalSettings.headerBackgroundColor.length > 0) {
            style.backgroundColor = portalSettings.headerBackgroundColor;
        }
        if (portalSettings.headerColor && portalSettings.headerColor.length > 0) {
            style.color = portalSettings.headerColor;
        }
        setTabStyle(style);
    }, [portalSettings]);

    useEffect(() => {
        setShowingAppMenu(false);
        if (pathname) {
            if (!isValidRoute(pathname) && appList.length > 0) {
                console.log(appList[0].route);
                navigate(appList[0].route);
            }
        }
    }, [pathname, appList])

    const isSelected = (tab) => {
        if (tab.route === '/portal') {
            if (pathname === '/portal') {
                return true;
            }
        } else {
            return pathname.startsWith(tab.route);
        }
    }

    return (
        <div className={'tab-labels' + (showingAppMenu ? ' showing' : '')} style={tabStyle}>
            {appList.map((t) => 
                <div 
                    role='button'
                    style={tabStyle} 
                    className={'tab-label' + (isSelected(t) ? ' selected' : '')} 
                    onClick={() => navigate(t.route)} 
                    key={t.id}>{t.label}</div>)
            }
        </div>
    )
}

export default AppTabs;