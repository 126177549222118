import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-regular-svg-icons';
import {getButtonStyle} from '_base'

function ImageCanvas(props) {
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(400);
    const [angleInDegrees, setAngleInDegrees] = useState(0);
    const canvasRef = useRef(null);

    useEffect(() => {
        resizeImage(props.image);
    }, []);

    const resizeImage = (imageObj) => {
        let context = canvasRef.current.getContext('2d');
		context.clearRect(0, 0, width, height);
			
		let imageAspectRatio = imageObj.width / imageObj.height;
		let canvasAspectRatio = width / height;
		let renderableHeight, renderableWidth, xStart, yStart;

		// If image's aspect ratio is less than canvas's we fit on height
		// and place the image centrally along width
		if(imageAspectRatio < canvasAspectRatio) {
			renderableHeight = height;
			renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
			xStart = (width - renderableWidth) / 2;
			yStart = 0;
		}
		// If image's aspect ratio is greater than canvas's we fit on width
		// and place the image centrally along height
		else if(imageAspectRatio > canvasAspectRatio) {
			renderableWidth = width;
			renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
			xStart = 0;
			yStart = (height - renderableHeight) / 2;
		}
        // Happy path - keep aspect ratio
		else {
			renderableHeight = height;
			renderableWidth = width;
			xStart = 0;
			yStart = 0;
		}
        context.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);

        let image = canvasRef.current.toDataURL("image/png", 1.0)
        props.onFinished(image);
    }
    /**
     * rotate clockwise
     */
    const rotateImageClockWise = () => {
        rotateImage(angleInDegrees + 90);
    }
    /**
     * rotate counter clockwise
     */
    const rotateImageCounterClockwise = () => {
        rotateImage(angleInDegrees - 90);
    }
    /**
     * rotate the image
     */
    const rotateImage = (angleInDegrees) => {
        var context = canvasRef.current.getContext('2d');
        context.clearRect(0, 0, width, height);

        // save the unrotated context of the canvas so we can restore it later
        // the alternative is to untranslate & unrotate after drawing
        context.save();
        // move to the center of the canvas
        context.translate(width/2,height/2);
        // rotate the canvas to the specified degrees
        context.rotate(angleInDegrees*Math.PI/180);
        // draw the image
        // since the context is rotated, the image will be rotated also
        context.drawImage(props.image,-props.image.width/2,-props.image.width/2);
        // we’re done with the rotating so restore the unrotated context
        context.restore();
        setAngleInDegrees(angleInDegrees);
    }
    const saveHandler = () => {
        let image = canvasRef.current.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
        props.onSave(image);
    }
    
    return(
        <div className='image-canvas'>
            <canvas width={width} height={height} ref={canvasRef}/>
            <FontAwesomeIcon icon={faUndo} onClick={rotateImageCounterClockwise} />
            <FontAwesomeIcon icon={faUndo} flip='horizontal' onClick={rotateImageClockWise} />
            <div className='button-holder'>
                <button style={getButtonStyle()} onClick={saveHandler}>Save</button>
            </div>
        </div>
    )
}

export default ImageCanvas;