import React from 'react';
import { useCore } from './core';
import { useAuth } from './auth';

const StripeContext = React.createContext(null);

function StripeProvider({children}) {
    const { runAction } = useCore();
    const { member } = useAuth();

    const createCustomer = () => {
        runAction('stripe_create_customer', {memberId: member.id}, () => {});
    }

    const provider = {
        createCustomer
    }

    return <StripeContext.Provider value={provider}>{children}</StripeContext.Provider>
}

function useStripe() {
    const context = React.useContext(StripeContext);
    if (!context) {
        throw new Error('useStripe must be used within a StripeProvider')
    }
    return context;
}

export { StripeProvider, useStripe }