import { useLang } from 'contexts/lang'
import { useSettings } from 'contexts/settings';
import React, { useEffect, useState } from 'react'
import {getButtonStyle} from '_base'
import { useSession } from 'contexts/session';

function Notifications() {
    const { dict } = useLang();
    const { tenant } = useSession();
    const { settings, saveSettings } = useSettings();
    const [notifyNewFile, setNotifyNewFile] = useState(settings.notifyNewFile);
    const [notifyNewProduct, setNotifyNewProduct] = useState(settings.notifyNewProduct);
    const [notifyNewEvent, setNotifyNewEvent] = useState(settings.notifyNewEvent);
    const [newsletter, setNewsletter] = useState(settings.newsletter);
    const [enewsletter, setEnewsletter] = useState(settings.enewsletter);
    const [roster, setRoster] = useState(settings.roster);

    useEffect(() => {
        if (settings) {
            setNotifyNewFile(settings.notifyNewFile);
            setNotifyNewProduct(settings.notifyNewProduct);
            setNotifyNewEvent(settings.notifyNewEvent);
            setNewsletter(settings.newsletter);
            setEnewsletter(settings.enewsletter);
            setRoster(settings.roster);
        }
    }, [settings])

    const saveSettingsHandler = () => {
        const data = {...settings, 
            notifyNewFile, notifyNewEvent, notifyNewProduct,
            newsletter, enewsletter, roster
        };
        saveSettings(data, () => alert('Your settings have been saved.'));
    }

    return (
        <div className='settings-view'>
            <h3>{dict.notifications}</h3>
            <div className='setting-item'>
                    <div className='setting-toggle'>
                        <input type='checkbox' checked={notifyNewFile} onChange={e => setNotifyNewFile(e.target.checked)} /><label>{dict.notifyFile}</label>
                    </div>
                    <div className='setting-toggle'>
                        <input type='checkbox' checked={notifyNewProduct} onChange={e => setNotifyNewProduct(e.target.checked)} /><label>{dict.notifyStore}</label>
                    </div>
                    <div className='setting-toggle'>
                        <input type='checkbox' checked={notifyNewEvent} onChange={e => setNotifyNewEvent(e.target.checked)} /><label>{dict.notifyEvent}</label>
                    </div>
                    {tenant.settings.newsletter &&
                        <div className='setting-toggle'>
                            <input type='checkbox' checked={newsletter} onChange={e => setNewsletter(e.target.checked)} /><label>{dict.mailNewsletter}</label>
                        </div>
                    }
                    {tenant.settings.enewsletter &&
                        <div className='setting-toggle'>
                            <input type='checkbox' checked={enewsletter} onChange={e => setEnewsletter(e.target.checked)} /><label>{dict.emailNewsletter}</label>
                        </div>
                    }
                    {tenant.settings.roster &&
                        <div className='setting-toggle'>
                            <input type='checkbox' checked={roster} onChange={e => setRoster(e.target.checked)} /><label>{dict.listInRoster}</label>
                        </div>
                    }
                </div>
                <button style={getButtonStyle()} onClick={saveSettingsHandler}>Save</button>
        </div>
    )
}

export default Notifications;