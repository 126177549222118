import React, { useEffect, useState } from 'react';
import { useSession } from './session';
import { useLang } from './lang';
import { useToken } from './token';
import { useAuth } from './auth';

const AppsContext = React.createContext(null);

function AppsProvider({children}) {
    const { dict } = useLang();
    const { sessionLoading, portalSettings, memberTypes } = useSession();
    const { token } = useToken();
    const { authLoading, member, isExpired } = useAuth();
    const [appList, setAppList] = useState([]);
    const [memberOptions, setMemberOptions] = useState([]);

    useEffect(() => {
        if (!sessionLoading && !authLoading) {
            // get the member type
            if (memberTypes.length > 0 && member) {
                const memberType = memberTypes.find((mt) => mt.id === member.type);
                initializeMemberOptions(memberType);
            }
            initializeAppList();
        }
    }, [sessionLoading, authLoading, member])

    const initializeAppList = () => {
        let list = [];
        if (token && !isExpired) {
            if (portalSettings.socialTab) {
                list.push({id: 0, label: dict.social, route: '/portal/member/social'})
            }
            if (portalSettings.activitiesTab) {
                list.push({id: 1, label: dict.activities, route: '/portal/member/activities'})
            }
            list.push({id: 2, label: 'Events', route: '/portal/member/events'});
            if (portalSettings.filesTab) {
                list.push({id: 3, label: dict.files, route: '/portal/member/files'})
            }
            if (portalSettings.storeTab) {
                list.push({id: 4, label: dict.store, route: '/portal/member/store'})
            }
            if (portalSettings.classifiedTab) {
                list.push({id: 5, label: dict.classifieds, route: '/portal/member/classifieds'})
            }
            if (portalSettings.rosterTab) {
                list.push({id: 6, label: dict.roster, route: '/portal/member/roster'})
            }
        } else if (token && isExpired) {
            list.push({id: 0, label: dict.membership, route: '/portal/member/membership'});
        } else {
            list.push({id: 0, label: 'Home', route: '/portal'});
            if (portalSettings.joinLink) {
                list.push({id: 1, label: 'Join/Renew Membership', route: '/portal/join'});
            }
            list.push({id: 2, label: 'Events', route: '/portal/events'});
            if (portalSettings.donateTab) {
                list.push({id: 3, label: dict.donate, route: '/portal/donate'});
            }
        }
        setAppList(list);
    }

    const initializeMemberOptions = (memberType) => {
        let list = [];
        if (token) {
            list.push({id: 'profile', label: dict.profile, route: '/portal/member/profile'});
            list.push({id: 'membership', label: dict.membership, route: '/portal/member/membership'});
            list.push({id: 'payments', label: dict.payments, route: '/portal/member/payments'});
            list.push({id: 'notifications', label: dict.notifications, route: '/portal/member/notifications'});
            if (memberType && memberType.allowChildren && !isExpired) {
                list.push({id: 'children', label: dict.additionalLogins, route: '/portal/member/children'});
            }
            list.push({id: 'password', label: dict.password, route: '/portal/member/password'});
            list.push({id: 'logout', label: dict.signOut, route: '/portal/logout'});
        } else {
            list.push({id: 'login', label: dict.signIn, route: '/portal/signin'});
        }
        setMemberOptions(list);
    }

    const isValidRoute = (route) => {
        const appIndex = appList.find(a => route.startsWith(a.route));
        const optionIndex = memberOptions.find(o => route.startsWith(o.route));
        return appIndex != -1 || optionIndex != -1;
    }

    const getFirstAppRoute = () => {
        return appList.length > 0 ? appList[0].route : '/portal';
    }

    const provider = {
        appList,
        memberOptions,
        isValidRoute,
        getFirstAppRoute
    }

    return <AppsContext.Provider value={provider}>{children}</AppsContext.Provider>
}

function useApps() {
    const context = React.useContext(AppsContext);
    if (!context) {
        throw new Error('useApps must be used within an AppsContext');
    }
    return context;
}

export { AppsProvider, useApps }