import React from 'react';
import { useAuth } from 'contexts/auth';
import { Outlet } from 'react-router-dom';
import AppTabs from 'components/appTabs';
import AppOptions from 'components/appOptions';

function Portal() {
    const {  member } = useAuth();

	return(
        <>
            {member ? 
                <div className='body-content'>
                    <div className='left-side'>
                        <AppTabs />
                        <AppOptions />
                        <div className='tab-content'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            :
                <div className='body-content'>
                    <div className='left-side'>
                        You are not authorized.
                    </div>
                </div>
            }
        </>
    )
}

export default Portal;