import React, { useEffect } from 'react';
import { useWorkerInfo } from 'contexts/purchasing/workerInfo';
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';

function PersonalInfo() {
    const { dict } = useLang();
    const { member} = useAuth()

    const {fname, setFname, 
        lname, setLname, 
        phone, setPhone,
        email, setEmail,
        swag, setSwag} = useWorkerInfo();
    

    useEffect(() => {
        if (member) {
            setFname(member.fname);
            setLname(member.lname);
            setEmail(member.email_address);
        }
    }, [member])

	/**
	 * render 
	 */
    return (
        <div className='personal-info'>
            <p>{dict.allFieldsRequired}</p>
            <label>{dict.firstName}</label>
            <input type='text' aria-required='true' value={fname} data-testid='fname' maxLength='24' onChange={(evt) => setFname(evt.target.value)} />
            <label>{dict.lastName}</label>
            <input type='text' aria-required='true' value={lname} data-testid='lname' maxLength='24' onChange={(evt) => setLname(evt.target.value)} />
            <label>{dict.phoneNumbersOnly}</label>
            <input type='phone' aria-required='true' value={phone} data-testid='phone' maxLength='12' onChange={(evt) => setPhone(evt.target.value)} />
            <label>{dict.emailAddress}</label>
            <input type='email' aria-required='true' value={email} data-testid='email' maxLength='48' onChange={(evt) => setEmail(evt.target.value)} />
            <label>Preferred thank you gift (while supplies last)</label>
            <select value={swag} onChange={(evt) => setSwag(evt.target.value)}>
                <option value='-'>None</option>
                <option value='s-white'>Small event shirt - White</option>
                <option value='m-white'>Medium event shirt - White</option>
                <option value='l-white'>Large event shirt - White</option>
                <option value='xl-white'>X-Large event shirt - White</option>
                <option value='2xl-white'>2X-Large event shirt - White</option>
                <option value='3xl-white'>3X-Large event shirt - White</option>
                <option value='s-gray'>Small event shirt - Gray</option>
                <option value='m-gray'>Medium event shirt - Gray</option>
                <option value='l-gray'>Large event shirt - Gray</option>
                <option value='xl-gray'>X-Large event shirt - Gray</option>
                <option value='2xl-gray'>2X-Large event shirt - Gray</option>
                <option value='3xl-gray'>3X-Large event shirt - Gray</option>
                <option value='s-black'>Small event shirt - Black</option>
                <option value='m-black'>Medium event shirt - Black</option>
                <option value='l-black'>Large event shirt - Black</option>
                <option value='xl-black'>X-Large event shirt - Black</option>
                <option value='2xl-black'>2X-Large event shirt - Black</option>
                <option value='3xl-black'>3X-Large event shirt - Black</option>
            </select>
        </div>
    )
}

export default PersonalInfo;