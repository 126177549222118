import { useLang } from 'contexts/lang';
import { useSettings } from 'contexts/settings';
import React, { useState } from 'react';
import {getButtonStyle} from '_base'

function Password() {
    const { setPassword } = useSettings();
    const { dict } = useLang();
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState(null);

    const savePassword = async () => {;
        try {
            await setPassword(password1, () => {
                setPassword1('');
                setPassword2('');
                alert('Your password has been changed.');
            });
        } catch (e) {
            setError(e.message);
        }
    }

    return (
        <div className='settings-view'>
            <h3>{dict.changePassword}</h3>
            <div className='setting-item'>
                <div>{dict.passwordInstructions}</div>
                <div className='setting-item'>
                    <label>{dict.password}</label>
                    <div><input type='password' value={password1} onChange={e => setPassword1(e.target.value)}/></div>
                </div>
                <div className='setting-item'>
                    <label>{dict.retypePassword}</label>
                    <div><input type='password' value={password2} onChange={e => setPassword2(e.target.value)}/></div>
                </div>
                <div>{error}</div>
                <div style={{textAlign:'center'}}>
                    <button style={getButtonStyle()} onClick={savePassword} disabled={!(password1 === password2 && password1.length > 0)}>Save Password</button>
                </div>
            </div>
        </div>
    )
}

export default Password;