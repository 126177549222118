import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';


function Loading({small}) {
    return (
        <div className={'loading' + (small ? ' small' : '')}><FontAwesomeIcon icon={faSpinner} spin /></div>
    )
}

export default Loading;