import { useLang } from 'contexts/lang';
import React from 'react';
import PaymentMethods from 'utils/paymentMethods';
import PaymentHistory from 'utils/paymentHistory';
import Stripe from "assets/Stripe-blurple.svg";

function Payments() {
    const { dict } = useLang();

    return (
        <div className='settings-view'>
             <h3>{dict.payments}</h3>
             <img src={Stripe} alt='Powered by Stripe'
                style={{height:'40px',width:'120px',cursor:'pointer'}} />
             <div className='setting-item' style={{width:'80%','margin':'auto'}}>
                <div>
                    <label>{dict.paymentMethods}</label>
                    <PaymentMethods />
                </div>
                <div>
                    <label>{dict.paymentHistory}</label>
                    <PaymentHistory />
                </div>
            </div>
        </div>
    )
}

export default Payments;