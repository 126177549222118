import React, {useState} from 'react';

function Quantity(props) {

    const decrement = (evt) => {
        if (props.value > 0) {
            props.onChange(props.value - 1);
        }
        evt.preventDefault();
        evt.stopPropagation();
    }

    const increment = (evt) => {
        props.onChange(props.value + 1);
        evt.preventDefault();
        evt.stopPropagation();
    }

    return (
        <div className='quantity'>
            <div className='decrement' onClick={decrement}>-</div>
            <div>{props.value}</div>
            <div className='increment' onClick={increment}>+</div>
        </div>
    )
}

export default Quantity;