import React, {useState} from 'react';
import {dictionaryList} from './languages/index';

// based on https://hco.medium.com/create-a-multi-language-website-with-react-context-api-10f9544bee09

const LangContext = React.createContext({
    userLanguage: 'en',
    dictionary: dictionaryList.en
});

function LangProvider({children}) {
    const defaultLanguage = window.localStorage.getItem('rcml-lang');
    const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'en');

    const provider = {
        userLanguage,
        dict: dictionaryList[userLanguage],
        userLanguageChange: newLanguage => {
            setUserLanguage(newLanguage);
            window.localStorage.setItem('rcml-lang', newLanguage);
        }}
    return <LangContext.Provider value={provider}>{children}</LangContext.Provider>
}

function useLang() {
    const context = React.useContext(LangContext);
    if (!context) {
        throw new Error('LangContext must be used within a LangContext');
    }
    return context;
}

export {LangProvider, useLang}