import React, { useState } from 'react';
import { useCore } from './core';

const CommentsContext = React.createContext(null);

function CommentsProvider({postId, children}) {
    const { runAction } = useCore();
    const [comments, setComments] = useState([]);

    const getComments = async () => {
        const data = {'parentId': postId};
        runAction('get_post_comments', data, (response) => {
            if (response.success) {
                setComments(response.posts);
            }
        });
    }

    const provider = {
        comments,
        getComments,
    }

    return <CommentsContext.Provider value={provider}>{children}</CommentsContext.Provider>
}

function useComments() {
    const context = React.useContext(CommentsContext);
    if (!context) {
        throw new Error('useComments must be used within a CommentsProvider');
    }
    return context;
}

export { CommentsProvider, useComments }