import React, { useEffect, useState } from 'react';
import { useSession } from 'contexts/session';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import Payment from 'purchasing/payment';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import { CompleteProducts } from 'purchasing/complete';
import { useAuth } from 'contexts/auth';
import { ProductsCompletionProvider } from 'contexts/productsCompletion';
import ProgressIndicator from 'components/progressIndicator';
import PersonalInfo from 'purchasing/personalInfo';
import PurchaseSummary from './purchaseSummary';
import { toSpliced } from '_base';

const cartSteps = [
    {id: 'personal', label: 'Personal Info', complete: false, state: 'active'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function PurchaseCart() {
    const { tenant } = useSession();
    const { member } = useAuth();
    const { setMemberId, setFname, setLname, setAddress1, setCity, setState, setZip, 
        setPhone, setEmail, isPiComplete } = usePersonalInfo();
    const { setDescription, description, agreement, setAgreement, paid, completed } = usePurchaseOrder();
    const [view, setView] = useState(0);
    const [steps, setSteps] = useState(cartSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        setDescription(`${tenant.name} store purchase`);
    }, [])

    useEffect(() => {
        if (member) {
            setMemberId(member.id);
            setFname(member.fname);
            setLname(member.lname);
            setAddress1(member.address);
            setCity(member.city);
            setState(member.state);
            setZip(member.zip);
            setPhone(member.phone);
            setEmail(member.email);
        }
    }, [member])

    useEffect(() => {
        if (isPiComplete) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true}));
        }
    }, [isPiComplete])

    useEffect(() => {
        if (agreement) {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 4, 1, {...steps[3], complete: true}));
        }
    }, [completed])

    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

/*
    const renderCartTotal = () => {
        return <div className='cart-total-view'>
                    <div className='cart-total'>{dict.cartSubtotal.replace('{0}', products.length)} {formatCurrency(productsTotal)}</div>
                    <div className='cart-total'>{dict.cartShipping} {formatCurrency(shippingTotal)}</div>
                    <div className='cart-total'>{dict.cartDiscount} {formatCurrency(discountTotal)}</div>
                    <div className='cart-total'>{dict.cartFee} {formatCurrency(tenant.settings.serviceFee)}</div>
                    <hr />
                    <div className='cart-total'>{dict.cartTotal} {formatCurrency(grandTotal)}</div>
                    <div>
                        <button style={getButtonStyle()} onClick={prevView}>{dict.backToStore}</button>
                        <button style={getButtonStyle()} onClick={nextView}>{dict.continue}</button>
                    </div>
                </div>
    }
*/
    const agreementHandler = (e) => {
        const val = e.target.value;
        setAgreement((val.toLowerCase() === 'i agree'));
    }

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <PersonalInfo />}
                {currentStep === 1 &&
                    <>
                        <p>
                            In order to facilitate this purchase you will be required to use a credit card in the next step.
                            This purchase will appear on your banks statement with the description {`"${description}"`} and show that
                            that the purchase was from a company called Middletwin.
                        </p>
                        <p>
                            By typing the words &quot;I agree&quot; in the space below, you agree that you will not dispute this
                            charge with your bank, and that if your bank disputes the charge on your behalf that you will take 
                            every reasonable action with the bank to get the bank to drop the disputed charge.
                        </p>
                        <p>
                            You also agree with the Middletwin <a href='https://www.middletwin.com/terms-of-use/' target='_blank'>Terms of use</a>.
                        </p>
                        <input type='text' placeholder='I agree' onChange={agreementHandler} />
                    </>
                }
                {currentStep === 2 && 
                    <PaymentLoaderProvider>
                        <Payment />
                    </PaymentLoaderProvider>
                }
                {currentStep === 3 &&
                    <ProductsCompletionProvider>
                        <CompleteProducts />
                    </ProductsCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                </div>
            </div>
            <PurchaseSummary />
        </div>
    )
}

export default PurchaseCart;