import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const StoreContext = React.createContext(null);

function StoreProvider({children}) {
    const { runAction } = useCore();
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        getProducts();
    }, [])

    const getProducts = async() => {
        runAction('get_store', {}, (response) => {
            setProductList(response.products);
        });
    }

    const provider = {
        productList,
    }

    return <StoreContext.Provider value={provider}>{children}</StoreContext.Provider>
}

function useStore() {
    const context = React.useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}

export { StoreProvider, useStore }