import React, { useEffect, useState, useRef } from 'react';
import { useLang } from 'contexts/lang';
import NextButton from 'utils/nextbutton';
import { useSignin } from 'contexts/signin';

/**
 * create password
 */
function CreatePassword(props) {
    const { dict } = useLang();
    const { setNewPassword, hasPassword } = useSignin();
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const passwordRef = useRef(null);

    useEffect(() => {
        passwordRef.current.focus();
    }, [])

    useEffect(() => {
        if (hasPassword) {
            props.onSetPassword();
        }
    }, [hasPassword])
	/**
	 * key up handler
	 */
	const keyUpHandler = (event) => {
		if (event.keyCode && event.keyCode === 13) {
			setNewPasswordHandler();
		}
	}
	/**
	 * set the password
	 */
	const setNewPasswordHandler = async () => {
        try {
            await setNewPassword(password);
        } catch (e) {
            setError(e.message);
        }
	}
	/**
	 * render
	 */
    return(
        <div>
            <p>{dict.createPassword}</p>
            <label>{dict.newPassword}</label>
            <div>
                <input type='password' data-testid='password' value={password} onChange={e => setPassword(e.target.value)} ref={passwordRef} onKeyUp={keyUpHandler} />
            </div>
            {error && <div className='input-error'>{error}</div>}
            <div className='button-container'>
                <NextButton onClick={setNewPasswordHandler} label={dict.next} />
            </div>
        </div>
    )
}

export default CreatePassword;