import React, { useEffect, useState } from 'react';
import { getSubdomain } from '_base';
import { useCore } from './core';

const SessionContext = React.createContext(null);
let match;

function SessionProvider({children}) {
    const { runNoAuthAction } = useCore();
    const [sessionLoading, setSessionLoading] = useState(true);
    const [subdomain, setSubdomain] = useState(null);
    const [tenant, setTenant] = useState(null);
    const [memberTypes, setMemberTypes] = useState(null);
    const [portalSettings, setPortalSettings] = useState(null);
    const [stripe, setStripe] = useState(null);
    const [pages, setPages] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [showingAppMenu, setShowingAppMenu] = useState(false);
    const [showingAppOptions, setShowingAppOptions] = useState(false);

    useEffect(() => {
        setSubdomain(getSubdomain());
    }, [])

    useEffect(() => {
        if (subdomain) {
            getTenant();
        }
    }, [subdomain]);

    const handleMediaChange = (match) => {
        setIsMobile(match.matches);
    }

    useEffect(() => {
        if (window.matchMedia) {
            match = window.matchMedia('(max-width:768px)');
            handleMediaChange(match);
            match.addListener(handleMediaChange);
        }
        return (() => {
            match.removeListener(handleMediaChange);
        })
    }, [])

    const getTenant = async () => {
        setSessionLoading(true);
        const data = {'subdomain': subdomain, 'memberTypes': true, 'pages': true};
        runNoAuthAction('get_tenant', data, (response) => {
            if (response) {
                setTenant(response.tenant);
                setMemberTypes(response.memberTypes);
                setPortalSettings(response.portalSettings);
                setStripe(response.stripe);
                setPages(response.pages);
                setSessionLoading(false);
            }
        });
    }
    
    const provider = {
        sessionLoading,
        subdomain,
        tenant,
        memberTypes,
        portalSettings,
        stripe,
        pages,
        isMobile,
        showingAppMenu, setShowingAppMenu,
        showingAppOptions, setShowingAppOptions
    }

    return <SessionContext.Provider value={provider}>{children}</SessionContext.Provider>
}

function useSession() {
    const context = React.useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
}

export { SessionProvider, useSession }