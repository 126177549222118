import React, { useState } from 'react';
import { useLang } from 'contexts/lang';
import NextButton from 'utils/nextbutton';
import { useSignin } from 'contexts/signin';

function ForgotPassword(props) {
    const { dict } = useLang();
    const { generateTemporaryPassword } = useSignin();
    const [error, setError] = useState(null);
    const [passwordReset, setPasswordReset] = useState(false);
    
    const generateTemporaryPasswordHandler = async () => {
        try {
            await generateTemporaryPassword(() => setPasswordReset(true));
        } catch (e) {
            setError(e.message);
        }
    }
    return (
        <>
            {error ?
                <div>
                    <div className='input-error'>
						{error}
					</div>
                </div>
            : (!passwordReset ?
                <div>
                    <p>{dict.forgotPassword1} {props.member.email_address}.
                    </p>
                    <div style={{textAlign:'center'}}>
                        <NextButton onClick={generateTemporaryPasswordHandler} label={dict.forgotPassword2} />
                    </div>
                </div>
            :
                <div>
                    <p>{dict.forgotPassword3} {props.member.email_address}.</p>
                </div>
            )}
        </>
    )
}

export default ForgotPassword;