import { useAuth } from "contexts/auth";
import { useLang } from "contexts/lang";
import { useMemberTypes } from "contexts/memberTypes";
import { useMembershipInfo } from "contexts/purchasing/membershipInfo";
import { usePurchaseOrder } from "contexts/purchasing/purchaseOrder";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useMemberLookup } from "contexts/memberLookup";
import { useSession } from "contexts/session";

function MembershipInfo() {
    const { member } = useAuth();
    const { tenant } = useSession();
    const { member:foundMember } = useMemberLookup();
    const { isLoading, types } = useMemberTypes();
    const { type, autoRenew, setAutoRenew } = useMembershipInfo();
    const { years, setYears } = usePurchaseOrder();
    const { dict } = useLang();
    const [membershipType, setMembershipType] = useState(null);
    
    useEffect(() => {
        setMembershipType(types.find(t => t.id === type))
    }, [])

    return (
        <div className='personal-info'>
            {!isLoading &&
                <>
                    <div style={{marginTop:'20px'}}>
                        <span style={{fontWeight:'600'}}>{`Membership Type: ${membershipType?.label}`}</span><br/>
                        <span style={{fontWeight:'600'}}>Current Expiration Date: {moment(foundMember ? foundMember.expiration : member.expiration).format('YYYY-MM-DD')}</span>
                    </div>
                    <p>How many years would you like to add to your membership?</p>
                    <select value={years} onChange={e => setYears(e.target.value)}>
                        {[...Array(tenant.settings.maxRenewYears)].map((_, index) => 
                            <option value={index+1}>{index+1} years</option>
                        )}
                    </select>
                    <div>
                        <p style={{fontWeight:'600'}}>New Expiration Date: {moment(foundMember ? foundMember.expiration : member.expiration).add(years, 'years').format('YYYY-MM-DD')}</p>
                    </div>
                    <p>You can enable automatic membership renewal using the same credit card you use today.<br/>
                        You can manage this settings and your saved credit cards in the member portal.<br/>
                        All credit cards and payment processing are handled by Stripe.</p>
                    <div style={{fontWeight:'600'}}>{dict.autoRenew} <input type='checkbox' checked={autoRenew} onChange={e => setAutoRenew(e.target.checked)} /></div>
                </>
            }
        </div>
    )
}

export default MembershipInfo;