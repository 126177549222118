import React, { useEffect } from 'react';
import { useLang } from 'contexts/lang';
import { useRegistrationInfo } from 'contexts/purchasing/registrationInfo';
import { useModels } from 'contexts/models';

function Models() {
    const { makes, models, getModels } = useModels();
    const { make, setMake, model, setModel } = useRegistrationInfo();
    const { dict } = useLang();

    useEffect(() => {
        if (makes.length > 0 && make.length > 0) {
            const makeId = makes.find(m => m.label === make).id;
            getModels(makeId);
        }
    }, [makes])

    const onChangeMakeHandler = (make) => {
        setMake(make);
        const makeId = makes.find(m => m.label === make).id;
        getModels(makeId);
    }

    const onChangeModelHandler = (model) => {
        setModel(model);
    }

    return (
        <div>
            <label>{dict.make}</label>
            <select value={make} onChange={e => onChangeMakeHandler(e.target.value)}>
                <option value=''>-- Select --</option>
                {makes.map((m) => <option key={m.id} value={m.label}>{m.label}</option>)}
            </select>
            <label>{dict.model}</label>
            <select value={model} onChange={(evt) => onChangeModelHandler(evt.target.value)}>
                <option value=''>-- Select --</option>
                {models.map(m => <option key={m.id} value={m.label}>{m.label}</option>)}
            </select>
        </div>
    )
}

export default Models;