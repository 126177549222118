import { useLang } from 'contexts/lang';
import { PageProvider, usePage } from 'contexts/page';
import { useSession } from 'contexts/session';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
    const { pages, portalSettings } = useSession();
    const [page, setPage] = useState(null);

    useEffect(() => {
        if (pages.length > 0 && portalSettings.homePage !== 0) {
            const homePage = pages.find(p => p.id === portalSettings.homePage);
            if (homePage) {
                setPage(homePage);
            }
        }
    }, [pages])

    return (
        <>
            {page 
                ? <PageProvider page={page}><PageView /></PageProvider>
                : <PanelsView />
            }
        </>
    )
}

function PanelsView() {
    const { portalSettings } = useSession();
    const { dict } = useLang();
    const navigate = useNavigate();

    const goToJoin = () => {
        navigate('/portal/join');
    }
    const goToEvents = () => {
        navigate('/portal/events');
    }
	const goToSignin = () => {
        navigate('/portal/signin');
	}
    const goToDonate = () => {
        navigate('/portal/donate');
    }

    return (
        <div className='public-panels'>
            <ul className='public-panel-list'>
                <li className='public-panel'>
                    <div className='panel-header'>
                        <h2>{dict.eventsPanelTitle}</h2>
                    </div>
                    <div className='panel-middle'>
                        <p>{dict.eventsPanel1}</p>
                    </div>
                    <div className='panel-footer'>
                        <button className='secondary' onClick={goToEvents}>{dict.eventsPanelAction}</button>
                    </div>
                </li>
                { portalSettings.joinLink && 
                <li className='public-panel'>
                    <div className='panel-header'>
                        <h2>{dict.joinPanelTitle}</h2>
                    </div>
                    <div className='panel-middle'>
                        <p>{dict.joinPanel1}</p>
                    </div>
                    <div className='panel-footer'>
                        <button className='secondary' onClick={goToJoin}>{dict.joinPanelAction}</button>
                    </div>
                </li>
                }
                <li className='public-panel'>
                    <div className='panel-header'>
                        <h2>{dict.memberPanelTitle}</h2>
                    </div>
                    <div className='panel-middle'>
                        <p>{dict.memberPanel1}</p>
                    </div>
                    <div className='panel-footer'>
                        <button className='secondary' onClick={goToSignin}>{dict.memberPanelAction}</button>
                    </div>
                </li>
                { portalSettings.donateTab &&
                <li className='public-panel'>
                    <div className='panel-header'>
                        <h2>{dict.donatePanelTitle}</h2>
                    </div>
                    <div className='panel-middle'>
                        <p>{dict.donatePanel1}</p>
                    </div>
                    <div className='panel-footer'>
                        <button className='secondary' onClick={goToDonate}>{dict.donatePanelAction}</button>
                    </div>
                </li>
                }
            </ul>
        </div>
    )
}

function PageView() {
    const { sections } = usePage();
    const { isMobile } = useSession();

    return (
        <div className={'browser-container' + ( isMobile ? ' mobile' : '')}>
            
            <div className={'page-editor' + ( isMobile ? ' mobile' : '')}>
                {sections.map((section, idx) => 
                    <div key={idx} className='page-section'>
                        {section.elements.map((element, eidx) => 
                            
                                <Element element={element} />
                            
                        )}
                    </div>
                    )}
            </div>
        </div>
    )
}

function Element({element}) {
    const [elementStyle, setElementStyle] = useState({});
    const [contentStyle, setContentStyle] = useState({});

    useEffect(() => {
        const settings = {element};
        if (settings) {
            setElementStyle({...elementStyle,
                width: settings.elementWidth + '%',
                height: settings.elementHeight + '%',
                borderTop: settings.borderTop  + 'px',
                borderRight: settings.borderRight  + 'px',
                borderBottom: settings.borderBottom  + 'px',
                borderLeft: settings.borderLeft  + 'px',
                borderColor: settings.borderColor,
                borderRadius: settings.borderRadius  + 'px'});
            setContentStyle({...contentStyle,
                width: settings.contentWidth + '%',
                fontSize: settings.fontSize + 'px',
                fontWeight: settings.fontWeight,
                color: settings.textColor,
                backgroundColor: settings.backgroundColor,
                justifySelf: settings.wrapAlign, 
                textAlign: settings.textAlign,
                marginTop: settings.marginTop + 'px',
                marginRight: settings.marginRight + 'px',
                marginBottom: settings.marginBottom + 'px',
                marginLeft: settings.marginLeft + 'px',
                paddingTop: settings.paddingTop + 'px',
                paddingRight: settings.paddingRight + 'px',
                paddingBottom: settings.paddingBottom + 'px',
                paddingLeft: settings.paddingLeft + 'px',
            });
        }
    }, [])

    return (
        <div className='page-element' style={elementStyle}>
            <div className='page-content' style={contentStyle}>
            {element.type === 'text' &&
                <div className='element-text' dangerouslySetInnerHTML={{__html: element.settings.text}}></div>
            }
            {element.type === 'image' &&
                <div style={{
                    width: element.settings.imageWidth + 'px',
                    height: element.settings.imageHeight + 'px',
                    backgroundImage:`url(${element.settings.imageData})`,
                    backgroundRepeat:'no-repeat',
                    backgroundPosition:'center',
                    backgroundSize:'cover'}} />
            }
        </div></div>
    )
}

export default Home;