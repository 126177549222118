import React, { useState } from 'react';

const MembershipInfoContext = React.createContext(null);

function MembershipInfoProvider({children}) {
    const [emailNewsletter, setEmailNewsletter] = useState(true);
	const [mailNewsletter, setMailNewsletter] = useState(false);
	const [listInDirectory, setListInDirectory] = useState(true); //this.list_in_directory.get("checked"),
    const [comments, setComments] = useState('Online Purchase');
	const [notify, setNotify] = useState(true);
	const [type, setType] = useState(null);
	const [tags, setTags] = useState([]);
    const [autoRenew, setAutoRenew] = useState(false);

    const provider = {
        emailNewsletter, setEmailNewsletter,
        mailNewsletter, setMailNewsletter,
        listInDirectory, setListInDirectory,
        comments, setComments,
        notify, setNotify,
        type, setType,
        tags, setTags,
        autoRenew, setAutoRenew,
    }

    return <MembershipInfoContext.Provider value={provider}>{children}</MembershipInfoContext.Provider>
}

function useMembershipInfo() {
    const context = React.useContext(MembershipInfoContext);
    if (!context) {
        throw new Error('useMembershipInfo must be used within a MembershipInfoProvider');
    }
    return context;
}

export { MembershipInfoProvider, useMembershipInfo }