import ProgressIndicator from 'components/progressIndicator';
import { EventProvider } from 'contexts/event';
import { EventCompletionProvider } from 'contexts/eventCompletion';
import { PaymentLoaderProvider } from 'contexts/purchasing/paymentLoader';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { CompleteEvent } from 'purchasing/complete';
import Payment from 'purchasing/payment';
import PersonalInfo from 'purchasing/personalInfo';
import SpacePicker from 'purchasing/spacePicker';
import React, { useEffect, useState } from 'react';
import VendorSummary from './vendorSummary';
import { toSpliced } from '_base';

const vendorSteps = [
    {id: 'personal', label: 'Personal Info', complete: false, state: 'active'},
    {id: 'spaces', label: 'Select Spaces', complete: false, state: 'todo'},
    {id: 'agreement', label: 'Agreement', complete: false, state: 'todo'},
    {id: 'pay', label: 'Pay', complete: false, state: 'todo'},
    {id: 'complete', label: 'Complete', complete: false, state: 'todo'},
];

function EventVendor({event}) {
    const { setDescription, spaces, agreement, setAgreement, paid, completed } = usePurchaseOrder();
    const { isPiComplete } = usePersonalInfo();
    const [steps, setSteps] = useState(vendorSteps);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        if (event) {
            setDescription(event.name);
        }
    }, [event])

    useEffect(() => {
        if (isPiComplete) {
            setSteps(toSpliced(steps, 0, 1, {...steps[0], complete: true}));
        }
    }, [isPiComplete])

    useEffect(() => {
        if (spaces.length > 0) {
            setSteps(toSpliced(steps, 1, 1, {...steps[1], complete: true}));
        }
    }, [spaces])

    useEffect(() => {
        if (agreement) {
            setSteps(toSpliced(steps, 2, 1, {...steps[2], complete: true}));
        }
    }, [agreement])

    useEffect(() => {
        if (paid) {
            setSteps(toSpliced(steps, 3, 1, {...steps[3], complete: true}));
            setCurrentStep(currentStep+1);
        }
    }, [paid])

    useEffect(() => {
        if (completed) {
            setSteps(toSpliced(steps, 4, 1, {...steps[4], complete: true}));
        }
    }, [completed])

    useEffect(() => {
        let noActives = [];
        steps.forEach(step => {noActives.push({...step, state: 'todo'})});
        setSteps(toSpliced(noActives, currentStep, 1, {...noActives[currentStep], state: 'active'}));
    }, [currentStep])

    const agreementHandler = (e) => {
        const val = e.target.value;
        setAgreement((val.toLowerCase() === 'i agree'));
    }

    return (
        <div className='event-registration'>
            <div className='registration-steps'>
                <ProgressIndicator steps={steps} activeStep={currentStep} />
                {currentStep === 0 && <PersonalInfo />}
                {currentStep === 1 && 
                    <EventProvider eventId={event.id}>
                        <SpacePicker eventId={event.id} />
                    </EventProvider>
                }
                {currentStep === 2 &&
                    <>
                        <p>
                            In order to facilitate this purchase you will be required to use a credit card in the next step.
                            This purchase will appear on your banks statement with the description {`"${event.name}"`} and show that
                            that the purchase was from a company called Middletwin.
                        </p>
                        <p>
                            By typing the words &quot;I agree&quot; in the space below, you agree that you will not dispute this
                            charge with your bank, and that if your bank disputes the charge on your behalf that you will take 
                            every reasonable action with the bank to get the bank to drop the disputed charge.
                        </p>
                        <p>
                            You also agree with the Middletwin <a href='https://www.middletwin.com/terms-of-use/' target='_blank'>Terms of use</a>.
                        </p>
                        <input type='text' placeholder='I agree' onChange={agreementHandler} />
                    </>
                }
                {currentStep === 3 &&
                    <PaymentLoaderProvider>
                        <Payment />
                    </PaymentLoaderProvider>
                }
                {currentStep === 4 &&
                    <EventCompletionProvider>
                        <CompleteEvent eventId={event.id} />
                    </EventCompletionProvider>
                }
                <div className='registration-buttons'>
                    <button onClick={() => setCurrentStep(currentStep-1)} disabled={currentStep === 0 || currentStep === steps.length - 1}>Back</button>
                    <button onClick={() => setCurrentStep(currentStep+1)} disabled={(currentStep === steps.length -1) || !steps[currentStep].complete}>Next</button>
                </div>
            </div>
            {event && <VendorSummary />}
        </div>
    )
}

export default EventVendor;