import { formatCurrency } from '_base';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Summary() {
    const { description, 
        serviceFee, 
        taxes, 
        donation,
        grandTotal,
        completed,
        resetPO
    } = usePurchaseOrder();
    const { resetPi } = usePersonalInfo();
    const navigate = useNavigate();

    const cancelHandler = () => {
        resetPO();
        resetPi();
        navigate('/portal');
    }

    const finishHandler = () => {
        navigate('/portal');
    }

    return (
        <div className='registration-summary'>
            <div className='registration-summary-title'>Donation Summary</div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Donation</div>
                <div className='item-amount'>{formatCurrency(donation)}</div>
            </div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Sub-total</div>
                <div className='item-amount'>{formatCurrency(donation)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Service fee</div>
                <div className='item-amount'>{formatCurrency(serviceFee)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Taxes</div>
                <div className='item-amount'>{formatCurrency(taxes)}</div>
            </div>
            <div className='registration-summary-total'>
                <div className='item-title'>Total</div>
                <div className='item-amount'>{formatCurrency(grandTotal)}</div>
            </div>
            <div className='registration-summary-buttons'>
                <button className='secondary' onClick={cancelHandler}>Cancel</button>
                <button disabled={!completed} onClick={finishHandler}>Finished</button>
            </div>
        </div>
    )
}

export default Summary;