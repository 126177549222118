import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentMethodForm from './paymentMethodForm';
import { useSession } from 'contexts/session';

function PaymentMethod({onFinished, onCancel}) {
    const { stripe } = useSession();
    const [promise, setPromise] = useState(null);

    useEffect(() => {
        console.log('paymentMethod::useEffect')
        setPromise(loadStripe(stripe));
    }, []);

    return (
        <div>
            {promise &&
                <Elements stripe={promise}>
                    <PaymentMethodForm 
                        onFinished={onFinished} 
                        onCancel={onCancel} />
                </Elements>
            }
        </div>
    )
}

export default PaymentMethod;