import React, { useEffect, useState } from 'react';
import { usePurchaseOrder } from './purchaseOrder';

const RegistrationInfoContext = React.createContext(null);

const REGKEY = 'registrationInfo';

function RegistrationInfoProvider({children}) {
    const { completed } = usePurchaseOrder();
    const [isLoading, setIsLoading] = useState(true);
    const [year, setYear] = useState('');
    const [make, setMake] = useState('');
    const [model , setModel] = useState('');
    const [trim, setTrim] = useState('');
    const [color, setColor] = useState('');
    const [engine, setEngine] = useState('');
    const [carClass, setCarClass] = useState('');
    const [isRegComplete, setIsRegComplete] = useState(false);

    useEffect(() => {
        setIsRegComplete(year.length > 0
            && make.length > 0
            && model.length > 0
            && color.length > 0
            && engine.length > 0
            && carClass.length > 0);
    }, [year, make, model, color, engine, carClass])

    useEffect(() => {
        if (!isLoading && window.sessionStorage) {
            const regJSONString = JSON.stringify({year, make, model, trim, color, engine, carClass});
            window.sessionStorage.setItem(REGKEY, regJSONString);
        }
    }, [year, make, model, trim, color, engine, carClass])

    useEffect(() => {
        if (window.sessionStorage) {
            const regJSONString = window.sessionStorage.getItem(REGKEY);
            if (regJSONString) {
                try {
                    const regJSON = JSON.parse(regJSONString);
                    setYear(regJSON.year);
                    setMake(regJSON.make);
                    setModel(regJSON.model);
                    setTrim(regJSON.trim);
                    setColor(regJSON.color);
                    setEngine(regJSON.engine);
                    setCarClass(regJSON.carClass);
                } catch (err) {
                    console.error(err);
                } finally {
                    window.sessionStorage.removeItem(REGKEY);
                }
            }
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (completed && window.sessionStorage) {
            window.sessionStorage.removeItem(REGKEY);
        }
    }, [completed])

    const resetRi = () => {
        window.sessionStorage.removeItem(REGKEY);
    }

    const provider = {
        year, setYear,
        make, setMake,
        model, setModel,
        trim, setTrim,
        color, setColor,
        engine, setEngine,
        carClass, setCarClass,
        isRegComplete,
        resetRi
    }

    return <RegistrationInfoContext.Provider value={provider}>{children}</RegistrationInfoContext.Provider>
}

function useRegistrationInfo() {
    const context = React.useContext(RegistrationInfoContext);
    if (!context) {
        throw new Error('useRegistrationInfo must be used within a RegistrationInfoProvider');
    }
    return context;
}

export { RegistrationInfoProvider, useRegistrationInfo }