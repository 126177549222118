import React, { useEffect } from 'react';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';

function PersonalInfo() {
    const { dict } = useLang();
    const { member} = useAuth()

    const {fname, setFname, 
        lname, setLname, 
        address1, setAddress1,
        city, setCity,
        state, setState,
        zip, setZip,
        country, setCountry,
        phone, setPhone,
        email, setEmail} = usePersonalInfo();
    

    useEffect(() => {
        if (member) {
            setFname(member.fname);
            setLname(member.lname);
            setAddress1(member.address);
            setCity(member.city);
            setState(member.state);
            setZip(member.zip);
            setEmail(member.email_address);
        }
    }, [member])

	/**
	 * render 
	 */
    return (
        <div className='personal-info'>
            <p>{dict.allFieldsRequired}</p>
            <label>{dict.firstName}</label>
            <input type='text' aria-required='true' value={fname} data-testid='fname' maxLength='24' onChange={(evt) => setFname(evt.target.value)} />
            <label>{dict.lastName}</label>
            <input type='text' aria-required='true' value={lname} data-testid='lname' maxLength='24' onChange={(evt) => setLname(evt.target.value)} />
            <label>{dict.address}</label>
            <input type='text' aria-required='true' value={address1} data-testid='addr' maxLength='48' onChange={(evt) => setAddress1(evt.target.value)} />
            <label>{dict.city}</label>
            <input type='text' aria-required='true' value={city} data-testid='city' maxLength='48' onChange={(evt) => setCity(evt.target.value)} />
            <label>{dict.state}</label>
            <input type='text' aria-required='true' value={state} data-testid='state' maxLength='2' onChange={(evt) => setState(evt.target.value)} />
            <label>{dict.zip}</label>
            <input type='text' aria-required='true' value={zip} data-testid='zip' maxLength='10' onChange={(evt) => setZip(evt.target.value)} />
            <label>Country</label>
            <input type='text' aria-required='true' value={country} data-testid='country' maxLength='64' onChange={(evt) => setCountry(evt.target.value)} />
            <label>{dict.phoneNumbersOnly}</label>
            <input type='phone' aria-required='true' value={phone} data-testid='phone' maxLength='12' onChange={(evt) => setPhone(evt.target.value)} />
            <label>{dict.emailAddress}</label>
            <input type='email' aria-required='true' value={email} data-testid='email' maxLength='48' onChange={(evt) => setEmail(evt.target.value)} />
        </div>
    )
}

export default PersonalInfo;