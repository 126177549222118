import React from 'react';
import { useLang } from 'contexts/lang';
import {formatPhoneNumber} from '_base';
import { useRoster } from 'contexts/roster';
import Avatar from 'components/avatar';

function RosterTab() {
    const { dict } = useLang();
    const { membersLoading, members } = useRoster();

    const getPhoneNumber = (member) => {
        if (member.phoneNumbers && member.phoneNumbers.length > 0) {
            return formatPhoneNumber(member.phoneNumbers[0].longCode);
        }
        return null;
    }

    return (
        <div>
            {membersLoading && <div>Loading...</div>}
            {members &&
                <div className='roster'>
                    <table >
                        <thead>
                            <tr><th></th><th>{dict.name}</th><th>{dict.email}</th><th>{dict.phoneNumber}</th><th>{dict.city}</th></tr>
                        </thead>
                        <tbody>
                            {members.map((m) => 
                                <tr key={m.id}>
                                    <td><Avatar value={m.avatar} width='50' height='50' name={m.name} /></td>
                                    <td>{m.name}</td>
                                    <td>{m.email_address}</td>
                                    <td>{getPhoneNumber(m)}</td>
                                    <td>{m.city}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default RosterTab;