import React, { useState } from 'react';
import { Elements, CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { getButtonStyle } from '_base';
import { usePaymentLoader } from 'contexts/purchasing/paymentLoader';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import Stripe from "assets/Stripe-blurple.svg";

function Payment() {
    const { promise, clientSecret } = usePaymentLoader();

    return (
        <div>
            {(promise && clientSecret) &&
                <Elements stripe={promise} options={{ clientSecret }}>
                    <PaymentForm />
                </Elements>
            }
        </div>
    )
}

export default Payment;

function PaymentForm() {
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);

    const { paid, setPaid } = usePurchaseOrder();
    const { completePayment } = usePaymentLoader();
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async ev => {
        ev.preventDefault();
        if (!stripe || !elements || paid) {
            return;
        }
        setProcessing(true);

        const payload = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}`
            },
            redirect: 'if_required',
        });

        if (payload.error) {
            completePayment(4);
            setError(`Payment Failed: ${payload.error.message}`);
            setProcessing(false);
        } else {
            completePayment(3);
            setError(null);
            setProcessing(false);
            setPaid(true);
        }
    }

    return (
        <form id='payment-form' onSubmit={handleSubmit}>
            <img src={Stripe} alt='Powered by Stripe' style={{height:'40px',width:'120px',cursor:'pointer'}} />
            <PaymentElement />
            {/*<CardElement />*/}
            {paid ? <div>Payment Succeeded</div> :
                <button id='submit' style={getButtonStyle()} disabled={paid || processing}>
                    <span>
                        {processing ? 'Processing...' : 'Pay Now'}
                    </span>
                </button>
            }
            {/* show any errors */}
            {error && <div role='alert'>{error}</div>}
        </form>
    )
}
