import React from 'react';

function ItemTile(props) {
    let imageStyle = null;
    if (props.image) {
        imageStyle = {backgroundImage:'url(' + props.image.imageData + ')'};
    }
    return(
        <div className='item-tile' onClick={props.onClick}>
            <div className='tile-image' style={imageStyle}></div>
            <div className='tile-name'>{props.label}</div>
            <div className='tile-price'>{props.price}</div>
        </div>
    );
}

export default ItemTile;