import React from 'react';
import { useCore } from './core';

const EvnetCompletionContext = React.createContext(null);

function EventCompletionProvider({children}) {
    const { runNoAuthAction } = useCore();

    const createEntry = async (entry, handler) => {
        runNoAuthAction('create_entry', entry, (response) => {
            handler(response)
        });
    }

    const createParticipant = async (data, handler) => {
        runNoAuthAction('create_participant', data, (response) => {
            handler(response);
        })
    }

    const createTicket = async (data, handler) => {
        runNoAuthAction('create_ticket', data, (response) => {
            handler(response);
        });
    }

    const workerSignup = async (data, handler) => {
        runNoAuthAction('worker_signup', data, (response) => {
            handler(response);
        });
    }

    const provider = {
        createEntry,
        createParticipant,
        createTicket,
        workerSignup,
    }

    return <EvnetCompletionContext.Provider value={provider}>{children}</EvnetCompletionContext.Provider>
}

function useEventCompletion() {
    const context = React.useContext(EvnetCompletionContext);
    if (!context) {
        throw new Error('useRegistration must be used within a EventCompletionProvider');
    }
    return context;
}

export { EventCompletionProvider, useEventCompletion }