import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useSession } from './session';

const ModelsContext = React.createContext(null);

function ModelsProvider({eventId, children}) {
    const { tenant } = useSession();
    const { runNoAuthAction } = useCore();
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);

    useEffect(() => {
        getMakes();
    }, [])

    const getMakes = async () => {
        runNoAuthAction('get_makes', {tenantId: tenant.id, eventId: eventId}, (response) => {
            if (response.success) {
                const makesList = response.makes;
                setMakes(makesList);
            }
        });
    }

    const getModels = async (makeId) => {
        runNoAuthAction('get_models', {tenantId: tenant.id, makeId}, (response) => {
            if (response.success) {
                setModels(response.models);
            }
        });
    }

    const provider = {
        makes,
        models,
        getModels
    }

    return <ModelsContext.Provider value={provider}>{children}</ModelsContext.Provider>
}

function useModels() {
    const context = React.useContext(ModelsContext);
    if (!context) {
        throw new Error('useModels must be used with a ModelsProvider');
    }
    return context;
}

export { ModelsProvider, useModels }