import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useSession } from './session';
import moment from 'moment';

const MemberLookupContext = React.createContext(null);

function MemberLookupProvider({children}) {
    const { runNoAuthAction } = useCore();
    const { tenant } = useSession();
    const [isFound, setIsFound] = useState(false);
    const [member, setMember] = useState(null);
    const [daysTillRenew, setDaysTillRenew] = useState(0);

    useEffect(() => {
        if (member) {
            if (tenant.settings.maxRenewDays > 0) {
                setDaysTillRenew(moment(member.expiration).diff(moment(), 'days') - tenant.settings.maxRenewDays);
            } else {
                setDaysTillRenew(0);
            }
        }
    }, [member])

    const lookupMember = async (email, handler) => {
        const data = {
			'tenant': tenant.id,
			'username': email
		};
        setIsFound(false);
        runNoAuthAction('lookup_member', data, (response) => {
            if (response.success) {
                setIsFound(true);
                setMember(response.member);
            }
            handler(response);
        });
    }

    const provider = {
        isFound,
        member,
        daysTillRenew,
        lookupMember,
    }

    return <MemberLookupContext.Provider value={provider}>{children}</MemberLookupContext.Provider>
}

function useMemberLookup() {
    const context = React.useContext(MemberLookupContext);
    if (!context) {
        throw new Error('useMemberLookup must be used within a MemberLookupProvider');
    }
    return context;
}

export { MemberLookupProvider, useMemberLookup }