import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { useToken } from './token';
import moment from 'moment';

const AuthContext = React.createContext(null);

function AuthProvider({children}) {
    const { runAction } = useCore();
    const { token } = useToken();
    const [authLoading, setAuthLoading] = useState(true);
    const [member, setMember] = useState(null);
    const [isExpired, setIsExpired] = useState(true);

    useEffect(() => {
        if (token) {
            authorize();
        } else {
            setMember(null);
            setAuthLoading(false);
        }
    }, [token])

    const authorize = async () => {
        setAuthLoading(true);
        runAction('member_authorize', {}, (response) => {
            if (response.success) {
                setMember(response.member);
                setIsExpired(!moment(response.member.expiration).isAfter(moment()))
            }
            setAuthLoading(false);
        });
    }

    const provider = {
        authLoading,
        member,
        isExpired,
        authorize,
    }

    return <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>
}

function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

export { AuthProvider, useAuth }