import React, { useEffect, useState } from 'react';
import { useSession } from './session';
import { useCore } from './core';

const MemberTypesContext = React.createContext(null);

function MemberTypesProvider({children}) {
    const { tenant } = useSession();
    const { runNoAuthAction } = useCore();
    const [isLoading, setIsLoading] = useState(true);
    const [types, setTypes] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        getMemberTypes();
    }, [])

    const getMemberTypes = async () => {
        let data = {
            "tenant": tenant.id
        };
        runNoAuthAction('get_available_membertypes', data, (response) => {
            if (response.success) {
                let memberTypes = response.memberTypes;
                setTypes(memberTypes);
            } else {
                setError(response.errorMessage);
            }
            setIsLoading(false);
        });
    }

    const provider = {
        isLoading,
        types,
        error,
    }

    return <MemberTypesContext.Provider value={provider}>{children}</MemberTypesContext.Provider>
}

function useMemberTypes() {
    const context = React.useContext(MemberTypesContext);
    if (!context) {
        throw new Error('useMemberTypes must be used within a MemberTypesProvider');
    }
    return context;
}

export { MemberTypesProvider, useMemberTypes }