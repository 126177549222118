import React, { useEffect, useState } from 'react';
import ItemTile from 'routes/portal/tile';
import ClassifiedView from './classifiedView';
import CreateClassified from './createClassified';
import {getButtonStyle} from '_base'
import { useLang } from 'contexts/lang';
import { useClassifieds } from 'contexts/classifieds';
import Loading from 'components/loading';
import { MemberProvider } from 'contexts/member';
import { useLoaderData, useNavigate } from 'react-router-dom';

export async function loader({params}) {
    return {listingId: params.listingId};
}

function Classifieds() {
    const { dict } = useLang();
    const loaderData = useLoaderData();
    const navigate = useNavigate();
    const { classifiedsLoading, classifieds, getClassifieds } = useClassifieds();
    const [classified, setClassified] = useState(null);
    const [creating, setCreating] = useState(false);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (classifieds.length > 0 && loaderData) {
            const { listingId } = loaderData;
            if (listingId) {
                const listing = classifieds.find(c => c.id == listingId);
                if (listing) {
                    setClassified(listing);
                }
            }
        } else if (!loaderData) {
            setClassified(null);
        }
    }, [classifieds, loaderData])

    const createHandler = () => {
        setCreating(true);
    }
    const newListingCreated = () => {
        setCreating(false);
        setUpdating(false);
        doneViewingListingHandler();
        getClassifieds();
    }
    const viewListing = (classified) => {
        navigate('/portal/member/classifieds/' + classified.id);
    }
    const doneViewingListingHandler = () => {
        navigate('/portal/member/classifieds');
    }
    const removeListingHandler = () => {
        doneViewingListingHandler();
        getClassifieds();
    }
    const updateListingHandler = () => {
        setUpdating(true);
    }
    const createItemTile = (classified) => {
        let image = null;
        if (classified.images.length > 0) {
            image = classified.images[0];
        }
        return(
            <ItemTile key={classified.id} 
                image={image}
                    onClick={() => viewListing(classified)}
                    label={classified.label}
                    price={'$' + classified.price.toFixed(2)} />
        );
    }
    return(
        <>
            {classifiedsLoading
                ? <Loading />
                : (classified && !updating) 
                ?
                    <div>
                        <MemberProvider>
                            <ClassifiedView 
                                classified={classified} 
                                onBack={doneViewingListingHandler} 
                                onRemove={removeListingHandler}
                                onUpdate={updateListingHandler} />
                        </MemberProvider>
                    </div>
                : (creating || updating)
                ?
                    <div>
                        <CreateClassified 
                            onDone={newListingCreated} 
                            classified={classified} />
                    </div>
                :
                <div className='events-container'>
                    <div className='events-label'>{dict.classifieds}<button style={getButtonStyle()} onClick={createHandler}>{dict.createClassified}</button></div>
                    <div className='events-content'>
                        <div className='item-tiles'>
                            {
                                classifieds.length > 0 
                                    ? classifieds.map((c) => createItemTile(c))
                                    : <div>{dict.beTheFirstClassified}</div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Classifieds;