import React, { useState } from 'react';
import Quantity from 'components/quantity';
import { formatCurrency } from '_base';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';

function TicketPicker(props) {
    const { tickets, setTickets } = usePurchaseOrder();
    const event = props.event;

    const addTicketHandler = (ticket) => {
        setTickets([...tickets, ticket]);
    }
    const removeTicketHandler = (ticket) => {
        let _tickets = [...tickets];
        _tickets.splice(tickets.findIndex(elem => elem.id === ticket.id), 1);
        setTickets(_tickets);
    }
    const nextHandler = () => {
        props.onComplete([...tickets]);
    }

    return (
        <div>
            <ul className='ticket-picker'>
                {event.tickets.map(t => 
                    <TicketItem key={t.id} ticket={t} onAdd={addTicketHandler} onRemove={removeTicketHandler} />
                )}
            </ul>
        </div>
    )
}

function TicketItem(props) {
    const [quantity, setQuantity] = useState(0);

    const changeQuantityHander = (qty) => {
        if (qty > quantity) {
            props.onAdd(props.ticket);
        } else if (qty < quantity) {
            props.onRemove(props.ticket);
        }
        setQuantity(qty);
    }

    return (
        <li>
            <div>{props.ticket.description}</div> 
            <div>{formatCurrency(props.ticket.price)}</div>
            <Quantity value={quantity} onChange={changeQuantityHander} />
        </li>
    )
}

export default TicketPicker;