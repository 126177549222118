import React, { useEffect, useState } from 'react';
import Loading from 'components/loading';
import moment from 'moment';
import { useShifts } from 'contexts/purchasing/shifts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/pro-regular-svg-icons';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useToken } from 'contexts/token';

function ShiftPicker() {
    const { loading, error, shifts, schedules } = useShifts();
    const [availableSchedules, setAvailableSchedules] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [availableShifts, setAvalableShifts] = useState([]);
    const { selectedShifts, setSelectedShifts } = usePurchaseOrder();
    const { token } = useToken();

    useEffect(() => {
        let openSchedules = [];
        if (token) {
            schedules.forEach(sched => {
                if (sched.shareType >= 2) {
                    openSchedules.push(sched);
                }
            })
            setAvailableSchedules(openSchedules);
        } else {
            let openSchedules = [];
            schedules.forEach(sched => {
                if (sched.shareType == 3) {
                    openSchedules.push(sched);
                }
            })
            setAvailableSchedules(openSchedules);
        }
    }, [shifts])

    const shiftSelectHandler = (shift) => {
        const shiftIndex = selectedShifts.findIndex(s => s.id === shift.id);
        if (shiftIndex !== -1) {
            let tempSelectedShfts = [...selectedShifts];
            tempSelectedShfts.splice(shiftIndex, 1);
            setSelectedShifts(tempSelectedShfts);
            //addMatchingShifts(shift);
        } else {
            setSelectedShifts([...selectedShifts, shift]);
            //removeMatchingShfts(shift);
        }
    }

    const addMatchingShifts = (shift) => {
        let tempAvailableShifts = [...availableShifts];
        shifts.forEach(s => {
            // if shift s is not currently in the available list
            if (tempAvailableShifts.findIndex(shft => shft.id === s.id) === -1) {
                if (compareShifts(s, shift) === 0 && s.id !== shift.id) {
                    tempAvailableShifts.push(s);
                }
            }
        });
        tempAvailableShifts.sort((s1, s2) => {
            return s1.id < s2.id ? -1 : s1.id > s2.id ? 1 : 0})
        setAvalableShifts(tempAvailableShifts);
    }

    const removeMatchingShfts = (shift) => {
        let tempAvailableShifts = [];
        availableShifts.forEach(s => {
            if (compareShifts(s, shift) !== 0 || s.id === shift.id) {
                tempAvailableShifts.push(s)
            }
        });
        setAvalableShifts(tempAvailableShifts);
    }

    const compareShifts = (s1, s2) => {
        if (s1.start === s2.start) {
            return 0;
        }
        /*if (s1.start + s1.duration > s2.start) {
            return 0;
        }
        if (s2.start + s2.duration > s1.start) {
            return 0;
        }*/
        return 1;
    }

    const showShifts = (schedule) => {
        setSelectedSchedule(schedule);
        let openShifts = [];
        shifts.forEach(shift => {
            if (schedule.id == shift.schedule) {
                openShifts.push(shift);
            }
        })
        setAvalableShifts(openShifts);
    }

    const renderShifts = () => {
        let shiftDate = null;

        return availableShifts.map(shift => 
            {
                let dateRow = null;
                // determine if we need to add a date row
                const sDate = moment(shift.shiftDate).format('dddd, MMMM Do YYYY');
                if (sDate !== shiftDate) {
                    dateRow = <ShiftDate key={sDate}>{sDate}</ShiftDate>;
                    shiftDate = sDate;
                }
                const shiftRow = <Shift 
                    key={shift.id} 
                    shift={shift} 
                    onSelect={shiftSelectHandler}
                />

                if (dateRow) {
                    return <>{dateRow}{shiftRow}</>
                } else {
                    return <>{shiftRow}</>
                }

                //const schedule = schedules.find(sched => sched.id === s.schedule);
                //return <Shift key={s.id} schedule={schedule} shift={s} onSelect={shiftSelectHandler} />
            }
        )
    }

    return (
        <div>
            {error && <div>{error}</div>}
            {loading ? <Loading /> :
                <div className='shiftPickerContainer'>
                    <div className='shiftList'>
                        <div className='scheduleTilesContainer'>
                            {availableSchedules.map(sched => 
                                <div className={'scheduleTile' + (sched.id === selectedSchedule?.id ? ' selected' : '')} role='button' onClick={() => showShifts(sched)}>{sched.name}</div>
                            )}
                        </div>
                        {availableShifts.length === 0
                            ? <div className='noShiftsMessage'>Select a schedule above to view the available shifts</div>
                            : <div className='selectedSchedule'>
                                {selectedSchedule?.instructions.length > 0 && <div className='selectedScheduleInstructions'>{selectedSchedule?.instructions}</div>}
                                <ul>
                                    {renderShifts()}
                                </ul>
                              </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

function ShiftDate({children}) {
    return (
        <li className='shiftDateRow'>
            <div className='row-title'>
                <div className='row-date'>
                    {children}
                </div>
            </div>
            <div className='date-container'></div>
        </li>
    )
}

function Shift({shift, onSelect}) {
    const [shiftDate, setShiftDate] = useState(moment(shift.shiftDate).hour(0).minute(0).second(0).millisecond(0));
    const [shiftStart, setShiftStart] = useState(moment(shiftDate).hour(shift.start).minute(shift.startMinute));
    const [shiftEnd, setShftEnd] = useState(moment(shiftStart).add(shift.duration, 'hours'));
    const [enabled, setEnabled] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const selectShift = () => {
        const selected = !enabled;
        setEnabled(selected);
        if (onSelect) {
            onSelect(shift);
        }
    }

    return (
        <li className={enabled ? 'selected' : ''}>
            <div className='shift-information'>
                
                <div className='shift-date'>
                    {shiftStart.format('h:mm a') + '-' + shiftEnd.format('h:mm a')}</div>
                <div className='shift-action'>
                    <FontAwesomeIcon icon={enabled ? faCheckSquare : faSquare} onClick={selectShift} />
                </div>
            </div>
            
        </li>
    )
}

export default ShiftPicker;