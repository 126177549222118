import Loading from 'components/loading';
import { EventProvider, useEvent } from 'contexts/event';
import { useSession } from 'contexts/session';
import { VoterProvider, useVoter } from 'contexts/voter';
import React, {useEffect, useRef, useState} from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

export async function loader({params}) {
    return params;
}

function VoterBallot() {
    const loaderData = useLoaderData();

    return (
        <EventProvider eventId={loaderData.eventid}>
            <VoterProvider voterId={loaderData.voterid}>
                <Ballot />
            </VoterProvider>
        </EventProvider>
    )
}

function Ballot() {
    const { tenant } = useSession();
    const { eventLoading, event } = useEvent();
    const { voterLoading, voter, getEntries, entriesLoading, entries,
        submittingBallot, submitBallot, ballotStatus, ballotStatusMessage } = useVoter();
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const classSelectRef = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!eventLoading) {
            getEntries(event.id);
        }
    }, [eventLoading])

    const handleSubmitBallot = () => {
        let votes = [];
        classSelectRef.current.forEach(ref => {
            if (ref.value != '0') {
                votes.push(ref.value);
            }
        })
        const data = {
            tenantId: tenant.id,
            voterId: voter.uuid,
            fname, lname, email,
            votes,
        }
        submitBallot(data);
    }

    const handleGoToPortal = () => {
        navigate('/portal');
    }

    return (
        <>
            {(eventLoading || voterLoading) ? <Loading /> : 
                <div className='voter-ballot'>
                    <h1>{event.name}</h1>
                    {moment().isBefore(moment(event.votingStart)) ?
                        <>
                            <h2>Voting has not begun</h2>
                            <p>Voting starts at {moment(event.votingStart).format('dddd, MMMM Do YYYY, h:mm a')}</p>
                            <div className='button-container'>
                                <button onClick={handleGoToPortal}>Go to club member portal</button>
                            </div>
                        </>
                        : moment().isAfter(moment(event.votingEnd)) ?
                        <>
                            <h2>Voting has ended</h2>
                            <p>Voting ended at {moment(event.votingEnd).format('dddd, MMMM Do YYYY, h:mm a')}</p>
                            <div className='button-container'>
                                <button onClick={handleGoToPortal}>Go to club member portal</button>
                            </div>
                        </>
                        : voter.voted ? 
                        <>
                            <h2>This ballot has been used</h2>
                            <p>Ballots can only be used one time. </p>
                            <div className='button-container'>
                                <button onClick={handleGoToPortal}>Go to club member portal</button>
                            </div>
                        </>
                        : ballotStatus === 'accepted' ?
                        <>
                            <h2>Your ballot has been accepted</h2>
                            <p>Your ballot and your votes have been accpted and counted.</p>

                            <div className='button-container'>
                                <button onClick={handleGoToPortal}>Go to club member portal</button>
                            </div>
                        </>
                        : 
                        <>
                            <h2>Voter Ballot</h2>
                            <p>Instructions: This is a one time use ballot. Do not use your browsers back button before
                                or after submitting the ballot and do not reload this page. You will not be able to re-submit
                                this ballot after the ballot has been submitted and accepted.<br/>
                                <br/>
                                Choose a single entry from each class. You do not need to make a choice for every class.
                                When you are done making your choices, submit the ballot and wait for the confirmation that
                                it was received.
                            </p>
                            <div className='ballot-form'>
                                <label>First name</label>
                                <input type='text' value={fname} onChange={(e) => setFname(e.target.value)} maxLength={64} />
                                <label>Last name</label>
                                <input type='text' value={lname} onChange={(e) => setLname(e.target.value)} maxLength={64} />
                                <label>Email</label>
                                <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} maxLength={64} />
                                {entriesLoading ? <Loading /> :
                                    entries.map((ntry, idx) => {
                                        return (
                                            <>
                                                <label>{`Class ${ntry.carClass} - ${ntry.classDescription}`}</label>
                                                <select key={ntry.carClass} ref={elem => classSelectRef.current[idx] = elem}>
                                                    <option value='0'>- no selection -</option>
                                                    {ntry.carNumbers.map(cn => <option key={cn}>{cn}</option>)}
                                                </select>
                                            </>
                                        )
                                    })
                                }
                                <div className='button-container'>
                                    <button onClick={handleSubmitBallot} disabled={submittingBallot}>Submit Ballot</button>
                                </div>
                                {ballotStatus === 'rejected' &&
                                    <div>Your ballot was rejected for the following reason: {ballotStatusMessage}</div>
                                }
                            </div>
                            <p>Voter Id: {voter.uuid}</p>
                        </>
                    }
                </div>
            }
        </>
    )
}


export default VoterBallot;