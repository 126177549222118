import { useApps } from 'contexts/apps';
import { useSession } from 'contexts/session';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function AppOptions() {

    return (
        <OptionsMenu />
    )
}

function OptionsMenu() {
    const { memberOptions } = useApps();
    const { showingAppOptions, setShowingAppOptions } = useSession();
    const navigate = useNavigate();

    const optionSelectionHandler = (route) => {
        setShowingAppOptions(false);
        navigate(route);
    }

    return (
        <div className={'member-options' + (showingAppOptions ? ' showing' : '')}>
            {memberOptions.map(opt => 
                <div 
                    key={opt.id} 
                    role='button'
                    className='tab-label'
                    onClick={() => optionSelectionHandler(opt.route)}>{opt.label}</div>)
            }
        </div>
    )
}

export default AppOptions;