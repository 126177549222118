import React from 'react';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';

const defaultMaskOptions = {
    prefix: '$', 
    suffix: '', 
    includeThousandsSeparator: true, 
    allowDecimal: true, 
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false
};

function DonationAmount() {
    const { donation, setDonation } = usePurchaseOrder();

    const handleAmountChange = (event) => {
        let currency = event.target.value;
        let number = Number(currency.replace(/[^0-9.-]+/g,""));
        setDonation(number);
    }

    return (
        <MaskedInput mask={createNumberMask(defaultMaskOptions)} type='text' name='price' value={donation} onChange={handleAmountChange} />
    )
}

export default DonationAmount;